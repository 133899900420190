import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Input,
  Table,
  Select,
  Divider,
  Space,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ADD_COLORS, GET_COLORS } from "apiServices/API_ENDPOINTS";
import { getData, postData } from "apiServices/common";

export default function AddColor({ colorList, setColorList }) {
  const [form] = Form.useForm();
  const [colorName, setColorName] = useState("");
  const [colorsList, setColorsList] = useState();

  const handleAdd = () => {
    form.validateFields().then((values) => {
      if (
        colorList.find(
          (item) => item?.color_id === values.color_id && item?.is_delete === 0,
        )
      ) {
        message.error("Color already added!");
        return;
      }

      setColorList([
        ...colorList,
        {
          ...values,
          key: Date.now(),
          is_delete: 0,
          name: colorsList.find((item) => item.id === values.color_id)?.name,
        },
      ]);
      form.resetFields();
    });
  };

  const addColorItem = async () => {
    let res = await postData(ADD_COLORS, { name: colorName, status: 1 });

    if (res) {
      let masterData = res?.data;
      setColorsList((oldArray) => [...oldArray, masterData]);
      setColorName("");
    }
  };

  const columns = [
    { title: "Color", dataIndex: "name", key: "name", width: "90%" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button onClick={() => handleDelete(record.key)} danger>
          Delete
        </Button>
      ),
      width: "10%",
    },
  ];

  const handleDelete = (key) => {
    setColorList(
      colorList.map((item) =>
        item.key === key ? { ...item, is_delete: 1 } : item,
      ),
    );
  };

  const getColorList = async () => {
    const bodyData = {
      per_page: 10000,
    };
    const res = await getData(GET_COLORS, false, bodyData);

    if (res) {
      setColorsList(res?.data?.data?.data);
    }
  };

  useEffect(() => {
    getColorList();
  }, []);

  console.log("colorList", colorList);

  return (
    <div>
      <Form form={form} layout="inline">
        <Form.Item
          name="color_id"
          rules={[
            { required: true, message: "Please select or create a Color ID!" },
          ]}
          style={{ width: "85%" }}
        >
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder="Select a Color or Create"
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option?.label
                ?.toLowerCase()
                ?.includes(input?.toLowerCase());
            }}
            size="small"
            dropdownMatchSelectWidth={false}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Space style={{ padding: "0 8px 4px" }}>
                  <Input
                    style={{ width: "100%" }}
                    placeholder="Enter New Color"
                    value={colorName}
                    onChange={(e) => setColorName(e.target.value)}
                  />
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={addColorItem}
                  >
                    Add Color
                  </Button>
                </Space>
              </>
            )}
            options={
              colorsList?.length &&
              colorsList.map((item) => ({
                label: item?.name,
                value: item?.id,
              }))
            }
          />
        </Form.Item>
        <Form.Item style={{ width: "10%" }}>
          <Button type="primary" onClick={handleAdd}>
            Add
          </Button>
        </Form.Item>
      </Form>
      <Table
        dataSource={colorList?.filter((val) => val?.is_delete === 0)}
        columns={columns}
        rowKey="id"
        pagination={false}
        bordered
      />
    </div>
  );
}
