import { useState, useEffect, useCallback, useMemo } from "react";
import {
  Table,
  Typography,
  Button,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { PACKING_LIST } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { ExportOutlined } from "@ant-design/icons";
import {
  PACKING_CREATE_PERMISSION,
  PACKING_EDIT_PERMISSION,
} from "routes/permissions";
import { hasPermission } from "utils/functions";
import AppContent from "../../../../common/AppContent";
import AppPageHeader from "../../../../common/PageHeader";
import ExtraContent from "../../../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../../../Configuration/CommonComponents/FilterComponent";

const PackingReqList = () => {
  // Router
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );
  const [permitList, setPermitList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(null);
  const [rangeData, setRangeData] = useState(null);

  // Antd
  const { Title } = Typography;

  // Router
  const navigate = useNavigate();

  // Filter open/close status update in localStorage
  useEffect(() => {
    const filterStatus = localStorage.getItem("filter_status");
    const parsedFilterStatus = filterStatus ? JSON.parse(filterStatus) : {};

    if (showFilter) {
      parsedFilterStatus[routerPath] = true;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    } else {
      parsedFilterStatus[routerPath] = false;
      localStorage.setItem("filter_status", JSON.stringify(parsedFilterStatus));
    }
  }, [showFilter, routerPath, location]);

  const getAllPermitList = useCallback(
    async (passedObject) => {
      setLoading(true);

      let filter = passedObject;
      const customQuery = `${PACKING_LIST}/?page=${currentPage}`;
      let res = await getData(customQuery, false, filter);

      if (res) {
        let masterData = res?.data;
        setPermitList(masterData?.data?.data);
        setTotalData(masterData?.data?.total);
        setRangeData(masterData?.data?.per_page);
      }
      setLoading(false);
    },
    [currentPage],
  );

  useEffect(() => {
    const existingFilter =
      JSON.parse(localStorage.getItem("purchase_order_filter_value")) || {};

    getAllPermitList(existingFilter);
  }, [currentPage, getAllPermitList]);

  const paginate = (page) => {
    setCurrentPage(page);
  };

  const onAddNew = () => {
    navigate("/merchandising/packing-requirements/entry");
  };

  const gotoUpdateRoute = (data) => {
    navigate(`/merchandising/packing-requirements/edit/${data.id}`);
  }

  const columns = [
    {
      title: "Original PO",
      dataIndex: ["po_maps", "original_po"],
      key: ["po_maps", "original_po"],
    },
    {
      title: "Internal PO",
      dataIndex: ["po_maps", "internal_po"],
      key: ["po_maps", "internal_po"],
    },
    {
      title: "Sales Contract",
      dataIndex: ["sales_contract", "reference_no"],
      key: "sales_contract",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Button
            size="small"
            onClick={() => gotoUpdateRoute(record)}
            icon={<ExportOutlined />}
            disabled={!hasPermission([PACKING_EDIT_PERMISSION])}
          >
            Edit
          </Button>
        );
      },
    },
  ];

  const memoizedCustomFilter = useMemo(
    () => (
      <FilterComponent
        tableData={permitList}
        filterAPI={getAllPermitList}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        filterName="purchase_order"
      />
    ),
    [showFilter],
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                // refresh={refresh}
                // setRefresh={setRefresh}
                handleAddItemOpen={onAddNew}
                disabled={!hasPermission([PACKING_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([PACKING_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Packing Requirements</Title>}
          />
        }
        content={
          <>
            {memoizedCustomFilter}

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={permitList}
                columns={columns}
                pagination={{
                  showSizeChanger: false,
                  current: currentPage,
                  total: totalData,
                  pageSize: rangeData,
                  onChange: (page) => paginate(page),
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default PackingReqList;
