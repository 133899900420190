import React from "react";

import { Line } from "@ant-design/charts";
import { Card, Col, Row, Table, Typography } from "antd";
import ToDay from "./ToDay";
import UpToDate from "./UpToDate";

const SewingProfitLoss = () => {
  return (
    <>
      <Row>
        <Col span={24}>
          <ToDay />
        </Col>
        <Col span={24}>
          <br />
          <Typography.Title level={4}>C2Sta</Typography.Title>
          <UpToDate />
        </Col>
      </Row>
    </>
  );
};

export default SewingProfitLoss;
