import React, { useState } from 'react';
import { Button, Form, Input, Table } from 'antd';

export default function AddSubStyle({ subStyleList, setSubStyleList }) {
  const [form] = Form.useForm();

  const handleAdd = () => {
    form.validateFields().then(values => {
      setSubStyleList([...subStyleList, { ...values, key: Date.now(), is_delete: 0 }]);
      form.resetFields();
    });
  };

  const columns = [
    { title: 'Sub Style No', dataIndex: 'sub_style_no', key: 'sub_style_no', width: '90%' },
    { title: 'Action', key: 'action', render: (_, record) => (
      <Button danger onClick={() => handleDelete(record.key)}>Delete</Button>
    ), width: '10%' }
  ];

  const handleDelete = (key) => {
    setSubStyleList(subStyleList.map(item => item.key === key ? { ...item, is_delete: 1 } : item));
  };

  return (
    <div>
      <Form form={form} layout="inline">
        <Form.Item name="sub_style_no" rules={[{ required: true, message: 'Please input Sub Style No!' }]} style={{ width: '85%' }}>
          <Input placeholder="Sub Style No" />
        </Form.Item>
        <Form.Item style={{ width: "10%"}}>
          <Button type="primary" onClick={handleAdd}>Add</Button>
        </Form.Item>
      </Form>
      <Table dataSource={subStyleList?.filter((val)=>val?.is_delete === 0)} columns={columns} rowKey="id" pagination={false} bordered />
    </div>
  );
}
