import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { Dropdown, Menu, message, Popconfirm, Table, Typography } from "antd";
import {
  DAILY_LINE_PLAN,
  ImportDailyLinePlan,
} from "apiServices/API_ENDPOINTS";
import {
  NO_PERMISSION_REQUIRED,
  DAILY_LINE_PLAN_IMPORT_PERMISSION,
} from "routes/permissions";
import { deleteData, getData } from "apiServices/common";
import AppContent from "common/AppContent";
import AppPageHeader from "common/PageHeader";
import ExtraContent from "components/Configuration/CommonComponents/ExtraContent";
import FilterComponent from "components/Configuration/CommonComponents/FilterComponent";
import { cglFormatDate, hasPermission } from "utils/functions";

const DailyLinePlanList = () => {
  // Router
  const navigate = useNavigate();
  const location = useLocation();
  const routerPath = location ? location.pathname : null;

  // States
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(0);
  const [showFilter, setShowFilter] = useState(
    localStorage.getItem("filter_status") && routerPath
      ? JSON.parse(localStorage.getItem("filter_status"))[routerPath]
      : false,
  );

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const getAppRoleMenu = useCallback(
    async (filterValues) => {
      setLoading(true);

      const query = `${DAILY_LINE_PLAN}?page=${currentPage}`;
      const bodyData = {
        ...(filterValues?.style_id && { style_id: filterValues?.style_id }),
        ...(filterValues?.line_id && { line_id: filterValues?.line_id }),
        ...(filterValues?.buyer_id && { buyer_id: filterValues?.buyer_id }),
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting vehicle info data");
      }
    },
    [currentPage],
  );

  const onAdd = () => {
    navigate("/digitalization/daily-line-plan/add");
  };

  const onEdit = (id) => {
    navigate(`/digitalization/daily-line-plan/edit/${id}`);
  };

  const onDelete = async (id) => {
    setLoading(true);
    const response = await deleteData(`${DAILY_LINE_PLAN}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success(
        response?.message || "Defect Line Plan Deleted Successfully",
      );
      refetch();
    } else {
      setLoading(false);
      message.error("Error Deleting Defect Line Plan");
    }
  };

  // Effects
  useEffect(() => {
    getAppRoleMenu();
  }, [refresh, getAppRoleMenu]);

  const tableColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (row) => cglFormatDate(row),
    },
    {
      title: "Line",
      dataIndex: ["line_info", "name"],
      key: "line_info_name",
    },
    {
      title: "Buyer",
      dataIndex: ["buyer_info", "name"],
      key: "buyer_info_name",
    },
    {
      title: "Style",
      dataIndex: ["style_info", "style_no"],
      key: "style_info_name",
    },
    {
      title: "Hourly Target (PC)",
      dataIndex: ["hourly_target_qty"],
      key: "hourly_target_qty",
      render: (row) => row || "N/A",
      align: "center",
    },
    {
      title: "Total SAM",
      dataIndex: ["ob_info", "total_sam", "total_updated_sam"],
      key: "ob_total_sam",
      align: "center",
      render: (_, record) => {
        return (
          record?.ob_info && (
            <span>
              {record?.ob_info?.total_updated_sam || record?.ob_info?.total_sam}
            </span>
          )
        );
      },
    },

    // {
    //   title: "PO",
    //   dataIndex: "po_map_info",
    //   key: "po_map_info_name",
    //   render:(row)=>  {
    //     return (
    //       row && <> (<span className="font-weight-bold">{row?.original_po}</span>)<span> {row?.internal_po}</span></>
    //     )
    //   }
    // },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  // {
                  //   label: (
                  //     <Popconfirm
                  //       title="Are you sure you want to delete?"
                  //       onConfirm={() => {
                  //         onDelete(record?.id);
                  //       }}
                  //     >
                  //       <span>
                  //         <DeleteTwoTone />
                  //       </span>
                  //       <span
                  //         style={{
                  //           color: "red",
                  //           marginLeft: 5,
                  //         }}
                  //       >
                  //         Delete
                  //       </span>
                  //     </Popconfirm>
                  //   ),
                  //   key: "1",
                  //   disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
                  // },
                  {
                    label: (
                      <>
                        <span>
                          <EditTwoTone />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            onEdit(record?.id);
                          }}
                        >
                          Edit
                        </span>
                      </>
                    ),
                    key: "2",
                    disabled: !hasPermission([NO_PERMISSION_REQUIRED]),
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  const memoizedCustomFilter = useMemo(
    () => (
      <FilterComponent
        tableData={tableData}
        filterAPI={getAppRoleMenu}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        filterName="digitalization_daily_line_plan"
      />
    ),
    [showFilter],
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={onAdd}
                disabled={!hasPermission([NO_PERMISSION_REQUIRED])}
                addButtonText={
                  hasPermission([NO_PERMISSION_REQUIRED])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
                hasImportButton={hasPermission([
                  DAILY_LINE_PLAN_IMPORT_PERMISSION,
                ])}
                acceptedFileTypes=".xlsx"
                uploadAPI={ImportDailyLinePlan}
              />
            }
            title={
              <Typography.Title level={5}>Daily Line Plan</Typography.Title>
            }
          />
        }
        content={
          <>
            {memoizedCustomFilter}

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default DailyLinePlanList;
