import {
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Form, InputNumber, Modal, Row, Select } from "antd";
import { PACKING_SIZE_LIST } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import { alertPop } from "apiServices/common/helper";
import { useEffect, useState } from "react";
import { commaSeparateNumber, isArrayAndHasValue } from "utils/functions";

const SizeTracking = (props) => {
  // Props
  const {
    selectedPoColorList,
    view,
    poDetailsData,
    PackReqForm,
    selectedPOUniqueID,
    isDrawerOpen,
    insertSizeTableData,
    styleNo,
  } = props;

  // States
  const [sizeTrackingData, setSizeTrackingData] = useState([{ unique_id: 0 }]);
  const [dynamicSizeList, setDynamicSizeList] = useState(null);

  // Antd
  const [sizeTrackingForm] = Form.useForm();
  const { Option } = Select;
  const { confirm } = Modal;
  const size_ratio_total = Form.useWatch("size_ratio_total", sizeTrackingForm);
  // Effects
  useEffect(() => {
    if (!isDrawerOpen) {
      // Reset track when drawer is closed
      resetTrack();
    }
  }, [isDrawerOpen]);

  const calculateSizeRatioTotal = () => {
    // Clone existing array for manipulation
    const sizeTrackingDataCopy = [...sizeTrackingData];

    // Calculate size ratio total
    const sizeRatioTotal = sizeTrackingDataCopy?.reduce((result, item) => {
      return result + item?.color_total;
    }, 0);

    // Set in form field
    sizeTrackingForm.setFieldsValue({
      size_ratio_total: sizeRatioTotal,
    });
  };

  useEffect(() => {
    // Get existing size tracking data and set to state
    const foundPoItem = poDetailsData?.find(
      (item) => item?.unique_id === selectedPOUniqueID,
    );

    if (foundPoItem) {
      const foundSizeTrackingData = foundPoItem?.colors;
      if (isArrayAndHasValue(foundSizeTrackingData)) {
        setSizeTrackingData(foundSizeTrackingData);

        // Get dynamic size list
        foundSizeTrackingData?.forEach((item) => {
          getPackingSizeList(item?.color_id);
        });

        // 
        // req_pack_total: foundPoItem?.carton_qty *foundPoItem?.carton_qty || 0,
        // Set req_pack_total,size_ratio_total fields value when first loads/drawer opens
        sizeTrackingForm.setFieldsValue({
          req_pack_total:
            foundPoItem?.carton_qty * foundPoItem?.total_quantity || 0,
          size_ratio_total: foundPoItem?.total_quantity || 0,
        });
      }
    }
    // Dependency limited to avoid re-render
    // Change dependency array with caution
  }, [isDrawerOpen, selectedPOUniqueID, poDetailsData]);

  const resetTrack = () => {
    setSizeTrackingData([{ unique_id: 0 }]);
    setDynamicSizeList(null);
  };

  const copyRow = (row_id) => {
    const foundPoItem = sizeTrackingData?.find(
      (item) => item?.unique_id === row_id,
    );
    const clonedFoundSizeItem = structuredClone(foundPoItem);
    const lastSizeItemId =
      sizeTrackingData[sizeTrackingData.length - 1]?.unique_id;

    const updatePoDetails = [
      ...sizeTrackingData,
      {
        ...clonedFoundSizeItem,
        unique_id: lastSizeItemId + 1,
      },
    ];
    // Calculate size ratio total
    const sizeRatioTotal = updatePoDetails?.reduce((result, item) => {
      return result + item?.color_total;
    }, 0);

    const findItem = poDetailsData?.find(
      (item) => item?.unique_id === selectedPOUniqueID,
    );

    // Set in form field
    sizeTrackingForm.setFieldValue("size_ratio_total", sizeRatioTotal);
    if (findItem) {
      sizeTrackingForm.setFieldsValue({
        req_pack_total: findItem?.carton_qty * sizeRatioTotal,
      });
    }

    setSizeTrackingData(updatePoDetails);
  };

  const removeRow = (row_index) => {
    confirm({
      title: "Do you want to delete this row?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        const updatedPoDetailsData = sizeTrackingData.filter(
          (item) => item?.unique_id !== row_index,
        );
        const findItem = poDetailsData?.find(
          (item) => item?.unique_id === selectedPOUniqueID,
        );
        // Calculate size ratio total
        const sizeRatioTotal = updatedPoDetailsData?.reduce((result, item) => {
          return result + item?.color_total;
        }, 0);

        // Set in form field
        sizeTrackingForm.setFieldsValue({
          size_ratio_total: sizeRatioTotal,
        });
        if (findItem) {
          sizeTrackingForm.setFieldsValue({
            req_pack_total: findItem?.carton_qty * sizeRatioTotal,
          });
        }
        setSizeTrackingData(updatedPoDetailsData);
      },
    });
  };

  const mergeSizeList = (newSizeList) => {
    // Merge dynamic size list with new size list
    if (dynamicSizeList) {
      const updatedDynamicSizeList = dynamicSizeList.map((item) => {
        const foundItem = newSizeList?.find(
          (newItem) => newItem?.size_id === item?.size_id,
        );
        if (foundItem) {
          return {
            ...item,
            ...foundItem,
          };
        } else {
          return item;
        }
      });

      newSizeList?.forEach((item) => {
        const foundItem = dynamicSizeList?.find(
          (newItem) => newItem?.size_id === item?.size_id,
        );
        if (!foundItem) {
          updatedDynamicSizeList.push(item);
        }
      });

      setDynamicSizeList(updatedDynamicSizeList);
    } else {
      setDynamicSizeList(newSizeList);
    }
  };

  const getPackingSizeList = async (colorID) => {
    const query = `${PACKING_SIZE_LIST}`;
    const internal_po = PackReqForm.getFieldValue("internal_po");
    const foundPoItem = poDetailsData?.find(
      (item) => item?.unique_id === selectedPOUniqueID,
    );
    const foundPoItemSubStyle = sizeTrackingData?.find(
      (item) => item?.unique_id === selectedPOUniqueID,
    );
    const style_id = foundPoItem?.style_id;
    const sub_style_id = foundPoItemSubStyle?.style_id;
    

    const bodyData = {
      internal_po: internal_po,
      style_id: style_id,
      sub_style_id,
      color_id: colorID,
    };

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      mergeSizeList(response?.data?.data);
    } else {
      alertPop("error", "Error", "Something Went Wrong, Please Try Again");
    }
  };

  const calculateColorTotal = (uniqueID) => {
    const sizeTrackingDataCopy = [...sizeTrackingData];
    const foundSizeItem = sizeTrackingDataCopy?.find(
      (item) => item?.unique_id === uniqueID,
    );

    const colorTotal = foundSizeItem?.sizes?.reduce((result, item) => {
      return result + item?.quantity;
    }, 0);
    foundSizeItem["color_total"] = colorTotal;

    setSizeTrackingData(sizeTrackingDataCopy);
  };

  const calculateReqColorTotal = (uniqueID) => {
    const sizeTrackingDataCopy = [...sizeTrackingData];
    const foundSizeItem = sizeTrackingDataCopy?.find(
      (item) => item?.unique_id === uniqueID,
    );

    const colorTotal = foundSizeItem?.req_sizes?.reduce((result, item) => {
      return result + item?.quantity;
    }, 0);
    foundSizeItem["req_color_total"] = colorTotal;

    setSizeTrackingData(sizeTrackingDataCopy);
  };

  const calculateReqSize = (value, sizeObject, uniqueID) => {
    const selectedPOItem = poDetailsData?.find(
      (item) => item?.unique_id === selectedPOUniqueID,
    );
    const cartonQty = selectedPOItem?.carton_qty;
    const calculatedSize = value * cartonQty;

    const sizeTrackingDataCopy = [...sizeTrackingData];
    const foundSizeItem = sizeTrackingDataCopy?.find(
      (item) => item?.unique_id === uniqueID,
    );

    if (foundSizeItem?.req_sizes && foundSizeItem?.req_sizes?.length > 0) {
      const foundSizeItemSize = foundSizeItem?.req_sizes?.find(
        (item) => item?.size_id === sizeObject?.size_id,
      );
      if (foundSizeItemSize) {
        foundSizeItemSize.quantity = calculatedSize || 0;
      } else {
        foundSizeItem?.req_sizes?.push({
          size_id: sizeObject?.size_id,
          quantity: calculatedSize,
        });
      }
    } else {
      foundSizeItem["req_sizes"] = [
        {
          size_id: sizeObject?.size_id,
          quantity: calculatedSize,
        },
      ];
    }

    calculateReqColorTotal(uniqueID);
    setSizeTrackingData(sizeTrackingDataCopy);
  };

  const onChangeSizeData = (value, sizeObject, uniqueID) => {
    const sizeTrackingDataCopy = [...sizeTrackingData];
    const foundSizeItem = sizeTrackingDataCopy?.find(
      (item) => item?.unique_id === uniqueID,
    );

    if (foundSizeItem?.sizes && foundSizeItem?.sizes?.length > 0) {
      const foundSizeItemSize = foundSizeItem?.sizes?.find(
        (item) => item?.size_id === sizeObject?.size_id,
      );
      if (foundSizeItemSize) {
        foundSizeItemSize.quantity = value || 0;
      } else {
        foundSizeItem?.sizes?.push({
          size_id: sizeObject?.size_id,
          quantity: value,
        });
      }
    } else {
      foundSizeItem["sizes"] = [
        {
          size_id: sizeObject?.size_id,
          quantity: value,
        },
      ];
    }

    // Calculate functions call when size change
    calculateColorTotal(uniqueID);
    calculateReqSize(value, sizeObject, uniqueID);
    calculateSizeRatioTotal();
    calculateReqPackTotal();
    setSizeTrackingData(sizeTrackingDataCopy);
  };

  const getSizeValue = (obj, sizeObject) => {
    return sizeTrackingData
      ?.find((item) => item?.unique_id === obj?.unique_id)
      ?.sizes?.find((item) => item?.size_id === sizeObject?.size_id)?.quantity;
  };

  const onChangeProperty = (value, uniqueID, property) => {
    const sizeTrackingDataCopy = [...sizeTrackingData];
    const foundSizeItem = sizeTrackingDataCopy?.find(
      (item) => item?.unique_id === uniqueID,
    );
    foundSizeItem[property] = value;
    setSizeTrackingData(sizeTrackingDataCopy);
  };

  const calculateReqPackTotal = () => {
    const sizeTrackingDataCopy = [...sizeTrackingData];
    const reqPackTotal = sizeTrackingDataCopy?.reduce((result, item) => {
      return result + item?.req_color_total;
    }, 0);
    // sizeTrackingForm.setFieldsValue({
    //   req_pack_total: reqPackTotal,
    // });
  };

  const setSubstyle = (index, value) => {
    const sizeTrackingDataCopy = structuredClone(sizeTrackingData);
    const foundItem = sizeTrackingDataCopy?.find(
      (item) => item?.unique_id === index,
    );
    const foundColor = selectedPoColorList?.find(
      (item) => item?.color_id === value,
    );
    
    foundItem["sub_style_id"] = foundColor?.sub_style_id;
    setSizeTrackingData(sizeTrackingDataCopy);
  };

  

  const setColor = (index, value) => {
    const sizeTrackingDataCopy = structuredClone(sizeTrackingData);
    const foundItem = sizeTrackingDataCopy?.find(
      (item) => item?.unique_id === index,
    );
    const foundColor = selectedPoColorList?.find(
      (item) => item?.sub_style_id === value,
    );
    foundItem["color_id"] = foundColor?.color_id;

    // Get size list data
    getPackingSizeList(foundColor?.color_id);

    // Set size tracking data
    setSizeTrackingData(sizeTrackingDataCopy);
  };
  const getStyleLabel = (color) => {
    let label = "";

    if (color?.sub_style_no && color?.color_name) {
      label = color?.sub_style_no + ": " + color?.color_name;
    } else if (!color?.sub_style_no && color?.color_name) {
      label = styleNo + ": " + color?.color_name;
    } else if (color?.sub_style_no && !color?.color_name) {
      label = color?.sub_style_no;
    } else {
      label = styleNo;
    }
    return label;
  };
  useEffect(() => {
    const findItem = poDetailsData?.find(
      (item) => item?.unique_id === selectedPOUniqueID,
    );
    if (findItem) {
      const carton_qty = findItem?.carton_qty;

      sizeTrackingForm.setFieldsValue({
        req_pack_total: parseInt(size_ratio_total) * parseFloat(carton_qty),
      });
    }
  }, [size_ratio_total]);
  return (
    <Form form={sizeTrackingForm} layout={"vertical"}>
      <Card>
        <Row gutter={6}>
          <Col span={4}>
            <Form.Item name="size_ratio_total" label="Size Ratio Total">
              <InputNumber
                style={{ width: "100%" }}
                disabled
                formatter={(value) => commaSeparateNumber(value)}
                precision={2}
                size="small"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="req_pack_total" label="Req Pack Total">
              <InputNumber
                style={{ width: "100%" }}
                disabled
                formatter={(value) => commaSeparateNumber(value)}
                precision={2}
                size="small"
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <div className="">
        <Card className="mt-3" title="Size Tracking">
          <div className="custome-table">
            <table>
              <thead>
                <tr>
                  <th colSpan={3}></th>
                  {dynamicSizeList?.length > 0 && (
                    <th
                      colSpan={dynamicSizeList?.length + 1}
                      className="bc-orange"
                    >
                      Size Ratio (pc)
                    </th>
                  )}
                  <th colSpan={2} className="bc-light-orange">
                    Actions
                  </th>
                </tr>
                <tr>
                  {/* Serial Number */}
                  <th key="header_serial_number" className="bc-java ">
                    SL
                  </th>
                  {/* Color */}
                  <th key="header_color" className="bc-java ">
                    Color
                  </th>
                  {/* Substyle */}
                  <th key="header_substyle" className="bc-java ">
                    Substyle
                  </th>
                  {/* All Sizes */}
                  {isArrayAndHasValue(dynamicSizeList) &&
                    dynamicSizeList?.map((size, index) => {
                      return (
                        <th
                          key={`header_size_${size?.size_id}`}
                          className="bc-java "
                        >
                          {size?.size_name}
                        </th>
                      );
                    })}
                  {/* Size Ratio Color Total */}
                  {dynamicSizeList && dynamicSizeList?.length > 0 && (
                    <th key={`size_ratio_color_total`} className="bc-java ">
                      Color Total
                    </th>
                  )}
                  {!view && (
                    <>
                      <th key="header_add_row" className="bc-java ">
                        Add
                      </th>
                      <th key="header_delete_row" className="bc-java ">
                        Delete
                      </th>
                    </>
                  )}
                </tr>
              </thead>

              <tbody>
                {sizeTrackingData && sizeTrackingData?.length > 0 ? (
                  <>
                    {sizeTrackingData.map((obj, index) => {
                      return (
                        <tr key={`table_parent_key_${index}`}>
                          {/* SL No. */}
                          <td className="t-body sticky">{index + 1}</td>

                          {/* Color */}
                          <td className="t-body sticky">
                            <Select
                              onChange={(value) => {
                                onChangeProperty(
                                  value,
                                  obj?.unique_id,
                                  "color_id",
                                );
                              }}
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              onSelect={(value, item) => {
                                getPackingSizeList(value);
                                setSubstyle(obj?.unique_id, value);
                              }}
                              defaultActiveFirstOption={false}
                              value={obj?.color_id}
                              size="small"
                            >
                              {isArrayAndHasValue(
                                selectedPoColorList?.colors,
                              ) &&
                                selectedPoColorList?.colors?.map(
                                  (color, index) => (
                                    <Option key={index} value={color?.color_id}>
                                      {color?.color_name}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </td>

                          {/* Substyle */}
                          <td className="t-body sticky">
                            <Select
                              onChange={(value) => {
                                onChangeProperty(
                                  value,
                                  obj?.unique_id,
                                  "sub_style_id",
                                );
                              }}
                              showSearch
                              style={{
                                width: "100%",
                              }}
                              placeholder="Search to Select"
                              optionFilterProp="children"
                              onSelect={(value, item) => {
                                setColor(obj?.unique_id, value);
                                setSubstyle(obj?.unique_id, value);
                              }}
                              defaultActiveFirstOption={false}
                              value={obj?.sub_style_id}
                              size="small"
                            >
                              {isArrayAndHasValue(
                                selectedPoColorList?.sub_styles,
                              ) &&
                                selectedPoColorList?.sub_styles?.map(
                                  (color, index) => (
                                    <Option key={index} value={color?.id}>
                                      {/* {color?.sub_style_no
                                      ? `${color?.sub_style_no} - ${color?.color_name}`
                                      : color?.color_name} */}
                                      {getStyleLabel(color)}
                                    </Option>
                                  ),
                                )}
                            </Select>
                          </td>

                          {/* All Size Ratio */}
                          {dynamicSizeList &&
                            dynamicSizeList?.length > 0 &&
                            dynamicSizeList?.map((sizeObject, index) => {
                              return (
                                <td className="t-body sticky">
                                  <InputNumber
                                    onChange={(value) => {
                                      onChangeSizeData(
                                        value,
                                        sizeObject,
                                        obj?.unique_id,
                                      );
                                    }}
                                    value={getSizeValue(obj, sizeObject)}
                                    formatter={(value) =>
                                      commaSeparateNumber(value)
                                    }
                                    precision={2}
                                    size="small"
                                  />
                                </td>
                              );
                            })}

                          {/* Size Ratio Color Total */}
                          {dynamicSizeList && dynamicSizeList?.length > 0 && (
                            <td className="t-body sticky">
                              <InputNumber
                                value={obj?.color_total}
                                disabled
                                formatter={(value) =>
                                  commaSeparateNumber(value)
                                }
                                size="small"
                              />
                            </td>
                          )}
                          {!view && (
                            <td
                              className="t-body"
                              onClick={() => {
                                copyRow(obj?.unique_id);
                              }}
                            >
                              <PlusCircleOutlined
                                className="cursore-pointer"
                                style={{ fontSize: "16px", color: "Green" }}
                              />
                            </td>
                          )}
                          {!view && (
                            <td
                              className="t-body"
                              onClick={() => {
                                removeRow(obj?.unique_id);
                              }}
                            >
                              <MinusCircleOutlined
                                className="cursore-pointer"
                                style={{ fontSize: "16px", color: "red" }}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </Card>
        <div className="centered-item-parent">
          <Button
            type="primary"
            onClick={() =>
              insertSizeTableData(sizeTrackingData, sizeTrackingForm)
            }
            size="small"
            style={{ width: 200, marginTop: 10 }}
          >
            Insert
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default SizeTracking;
