import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Table,
  Typography,
  Drawer,
  Row,
  Col,
  Space,
  Popover,
  Descriptions,
  Image,
  message,
  Card,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./index.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { onGetWarehouseList } from "../../../redux/actions";
import HandleNotification from "../../../common/Notification";
import { postData } from "../../../apiServices/common";
import {
  GET_ASSIGN_SUMMARY_ENDPOINT,
  RAW_MATERIAL_ADD,
  RAW_MATERIAL_UPDATE,
} from "../../../apiServices/API_ENDPOINTS";
import { getMovementType } from "../../../common/helper/helper";
import WarehouseForm from "./WarehouseForm";
import EntryForm from "./EntryForm";
import {
  commaSeparateNumber,
  hasPermission,
  isArrayAndHasValue,
} from "../../../utils/functions";
import {
  INVENTORY_CREATE_PERMISSION,
  INVENTORY_EDIT_PERMISSION,
} from "../../../routes/permissions";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);

  const form = useContext(EditableContext);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      handleSave(record, values);
    } catch (errInfo) {
      HandleNotification("error", "bottomRight", "Something went wrong!", null);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
      >
        <Input ref={inputRef} onChange={save} style={{ width: "100px" }} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const BOMTable = (props) => {
  // Props
  const {
    selectedBOM,
    loading,
    setInventoryItems,
    movement,
    inventoryFormValues,
    styleDetails,
    onEdit,
    getItemDetailsData,
    form,
  } = props;

  // States
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [assignSummary, setAssignSummary] = useState(null);
  const [trackFormInitialData, setTrackFormInitialData] = useState(null);
  const [BOMDetails, setBOMDetails] = useState(selectedBOM);
  const [tempRowData, setTempRowData] = useState([]);
  const [isItemUpdate, setIsItemUpdate] = useState(false);

  // Redux
  const dispatch = useDispatch();
  const warehouseList = useSelector(({ warehouse }) => warehouse.warehouseList);

  // Antd
  const [itemTrackingForm] = Form.useForm();
  const [tableItemSearchForm] = Form.useForm();
  const [entryForm] = Form.useForm();
  const tracks_watch = Form.useWatch("tracks", itemTrackingForm);

  // Effects
  useEffect(() => {
    if (assignSummary) {
      let tracksArray = [];
      let tempRowDataInitial = [];

      assignSummary.forEach((item, index) => {
        if (item?.total_quantity >= 0 && item?.shipment_qty >= 0) {
          // Prepare items in tracksArray
          tracksArray.push({
            warehouse_id: item?.bins_info?.rack_info?.warehouse_id,
            rack_id: item?.bins_info?.rack_id,
            bin_id: item?.bins_info?.id,
            id: item?.id,
            history_id: item?.history_id || 0,
            item_id: item?.item_id || null,
            total_balance: item?.total_balance || 0,
            total_shipment: item?.total_shipment || 0,
            shipment_qty: item?.shipment_qty || 0,
            total_quantity: item?.total_quantity || 0,
          });

          // Prepare items in tempRowDataInitial
          const filteredWarehouse =
            isArrayAndHasValue(warehouseList) &&
            warehouseList.find(
              (wareItem) =>
                Number(wareItem?.id) ===
                Number(item?.bins_info?.rack_info?.warehouse_id),
            );
          const filteredRack = filteredWarehouse?.racks_info?.find(
            (rackItem) =>
              Number(rackItem.id) === Number(item?.bins_info?.rack_id),
          );
          const racksList = filteredWarehouse?.racks_info;
          const binList = filteredRack?.bins_info;

          tempRowDataInitial.push({
            warehouse_id: item?.bins_info?.rack_info?.warehouse_id,
            row_id: index,
            racksList: racksList,
            binList: binList,
          });
        }
      });

      // Set tempRowDataInitial to tempRowData
      setTempRowData(tempRowDataInitial);

      // Set track form intial list data
      tracksArray && setTrackFormInitialData(tracksArray);

      // Get total data
      const totalRollQty = tracksArray?.reduce((result, item) => {
        return result + Number(item?.shipment_qty);
      }, 0);
      const totalReceiveQty = tracksArray?.reduce((result, item) => {
        return result + Number(item?.total_quantity);
      }, 0);

      // Set total values to form
      entryForm.setFieldsValue({
        roll_quantity: totalRollQty,
        receive_quantity: totalReceiveQty,
      });
    }
  }, [assignSummary, warehouseList, entryForm]);

  useEffect(() => {
    if (trackFormInitialData) {
      itemTrackingForm.setFieldsValue({
        tracks: trackFormInitialData,
      });
    }
  }, [trackFormInitialData, itemTrackingForm]);

  useEffect(() => {
    // update BOMDetails when selectedBOM is changed
    if (selectedBOM) {
      setBOMDetails(selectedBOM);
    }
  }, [selectedBOM]);

  const onAssign = async (record, type) => {
    if (type === "update") {
      setIsItemUpdate(true);
    }

    // Check if all required form values are given
    try {
      const values = await form.validateFields();
      if (!values) {
        HandleNotification(
          "error",
          "bottomRight",
          `Error: Please check form values`,
          null,
        );
      }
    } catch (errorInfo) {
      isArrayAndHasValue(errorInfo?.errorFields) &&
        errorInfo?.errorFields?.forEach((item) => {
          HandleNotification(
            "error",
            "bottomRight",
            `${item?.errors[0]}`,
            null,
          );
        });
      return;
    }

    const formValues = form.getFieldsValue();

    const bodyData = {
      category_id: record?.item_category_info?.id,
      item_id: record?.item_id,
      entry_date: formValues?.entry_date,
      sales_contract_id:
        styleDetails?.style?.active_sales_contract_map_info?.sales_contract_id,
      style_id: styleDetails?.style?.id,
      type: isItemUpdate ? "edit" : "add",
    };

    if (bodyData) {
      let response = await postData(GET_ASSIGN_SUMMARY_ENDPOINT, bodyData);

      if (response && response?.code === 200) {
        setAssignSummary(response?.data);
        HandleNotification(
          "success",
          "bottomRight",
          "Assign Data Fetched Successfully",
          null,
        );
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Something went wrong!",
          null,
        );
      }

      dispatch(onGetWarehouseList());
      setSelectedRecord(record);
      setOpenDrawer(true);
    } else {
      message.error("Data not provided in body");
    }
  };

  const onWarehouseSelect = (selectedWarehouseId, rowId) => {
    // Current tracks array from form fields
    const tracksArray = itemTrackingForm.getFieldsValue().tracks;
    // Reset current selected rack and bin
    tracksArray[rowId].rack_id = null;
    tracksArray[rowId].bin_id = null;
    itemTrackingForm.setFieldsValue({
      tracks: tracksArray,
    });

    // Filter racks list based in selected warehouse
    const filteredWarehouse =
      warehouseList &&
      warehouseList.find(
        (item) => Number(item.id) === Number(selectedWarehouseId),
      );
    const racksList = filteredWarehouse?.racks_info;

    // Iterate and push racklist data to tempRowData to show options in rack dropdown
    const tempRowDataCopy = [...tempRowData];

    if (tempRowDataCopy.length > 0) {
      const filteredRowData = tempRowDataCopy.find(
        (item) => item.row_id === rowId,
      );
      if (filteredRowData) {
        filteredRowData.warehouse_id = selectedWarehouseId;
        filteredRowData.racksList = racksList;
      } else {
        tempRowDataCopy.push({
          warehouse_id: selectedWarehouseId,
          row_id: rowId,
          racksList: racksList,
        });
      }
    } else {
      tempRowDataCopy.push({
        warehouse_id: selectedWarehouseId,
        row_id: rowId,
        racksList: racksList,
      });
    }

    setTempRowData(tempRowDataCopy);
  };

  const onRackSelect = (selectedRackId, rowId) => {
    // Current tracks array from form fields
    const tracksArray = itemTrackingForm.getFieldsValue().tracks;

    // Reset current selected bin
    tracksArray[rowId].bin_id = null;
    itemTrackingForm.setFieldsValue({
      tracks: tracksArray,
    });

    // Filter bin list based in selected selectedRackId
    const tempRowDataCopy = [...tempRowData];
    const currentRowData = tempRowDataCopy.find(
      (item) => item.row_id === rowId,
    );
    const filteredRack = currentRowData?.racksList?.find(
      (item) => Number(item.id) === Number(selectedRackId),
    );
    const binList = filteredRack?.bins_info;

    if (binList && binList.length > 0) {
      // Replace binList in currentRowData
      currentRowData.binList = binList;
    }

    setTempRowData(tempRowDataCopy);
  };

  const getRackList = (rowId) => {
    const tempRowDataCopy = [...tempRowData];
    // Get rackslist from tempRowDataCopy filtered by rowId
    const filteredRowData = tempRowDataCopy.find(
      (item) => item.row_id === rowId,
    );

    if (filteredRowData) {
      return filteredRowData?.racksList;
    } else {
      // If there is no data from filteredRowData, then return the initial racksList based on initial warehouse id
      const initialWarehouseId = trackFormInitialData[rowId]?.warehouse_id;
      const filteredWarehouse =
        warehouseList &&
        warehouseList.find(
          (item) => Number(item.id) === Number(initialWarehouseId),
        );
      const racksList = filteredWarehouse?.racks_info;

      return racksList;
    }
  };

  const getBinList = (rowId) => {
    // do the same thing like getRackList function but now using rowId to filter bin list
    const tempRowDataCopy = [...tempRowData];
    const filteredRowData = tempRowDataCopy.find(
      (item) => item.row_id === rowId,
    );

    if (filteredRowData) {
      return filteredRowData?.binList;
    } else {
      // If there is no data from filteredRowData, then return the initial BINList based on initial warehouse id and rack id
      const initialWarehouseId = trackFormInitialData[rowId]?.warehouse_id;
      const initialRackId = trackFormInitialData[rowId]?.rack_id;
      const filteredWarehouse =
        warehouseList &&
        warehouseList.find(
          (item) => Number(item.id) === Number(initialWarehouseId),
        );
      const filteredRack = filteredWarehouse?.racks_info?.find(
        (item) => Number(item.id) === Number(initialRackId),
      );
      const binList = filteredRack?.bins_info;
      return binList;
    }
  };

  const formatNumberWithCommas = (number) => {
    return Number(parseFloat(number).toFixed(2)).toLocaleString(undefined, {
      maximumFractionDigits: 2,
    }); // Format number with commas
  };

  const defaultColumns = [
    {
      title: "Item Type",
      dataIndex: "item_category_info",
      key: "name",
      render: (item, record) => {
        return (
          <Popover placement="topLeft" content={<RowInfoUI rowData={record} />}>
            <div>{item?.name}</div>
          </Popover>
        );
      },
    },
    {
      title: "Item",
      dataIndex: "item_info",
      key: "name",
      width: 450,
      render: (item, record) => {
        return (
          <Popover placement="topLeft" content={<RowInfoUI rowData={record} />}>
            <div>
              <Space>
                <div>{item?.name}</div>
                <div>
                  <Popover
                    content={
                      <Descriptions
                        column={1}
                        bordered
                        style={{
                          height: 300,
                          width: 350,
                          overflow: "auto",
                        }}
                      >
                        {item?.attribute_value_info?.map((attrInfo, index) => {
                          return (
                            <Descriptions.Item
                              label={attrInfo?.attribute_info?.name}
                              key={index}
                            >
                              {attrInfo?.attribute_value}
                            </Descriptions.Item>
                          );
                        })}

                        {item?.image_path ? (
                          <Descriptions.Item
                            label="Item Image"
                            labelStyle={{
                              backgroundColor: "white",
                              borderRight: "none",
                              alignContent: "center",
                            }}
                          >
                            <Image.PreviewGroup>
                              <Image
                                width={50}
                                height={50}
                                path={item?.image_path}
                                src={`${process.env.REACT_APP_BASE_URL}/${item?.image_path}`}
                              />
                            </Image.PreviewGroup>
                          </Descriptions.Item>
                        ) : null}
                      </Descriptions>
                    }
                  >
                    <div className="centered-item-parent">
                      <InfoCircleOutlined
                        className="cursore-pointer"
                        style={{
                          fontSize: "20px",
                          color: "green",
                        }}
                      />
                    </div>
                  </Popover>
                </div>
              </Space>
            </div>
          </Popover>
        );
      },
    },
    {
      title: "Color",
      dataIndex: "color_info",
      key: "name",
      render: (color_info, record) => {
        return (
          <Popover placement="topLeft" content={<RowInfoUI rowData={record} />}>
            <div>{color_info?.name}</div>
          </Popover>
        );
      },
    },
    {
      title: "Sub-Style",
      dataIndex: "sub_style_map",
      key: "name",
      render: (sub_style_info, record) => {
        return (
          <Popover placement="topLeft" content={<RowInfoUI rowData={record} />}>
            <div>{sub_style_info?.sub_style_no}</div>
          </Popover>
        );
      },
    },
    {
      title: "O.Qty",
      dataIndex: "order",
      key: "order",
      render: (text, record) => {
        return (
          <Popover placement="topLeft" content={<RowInfoUI rowData={record} />}>
            <div>{formatNumberWithCommas(record?.order_quantity)}</div>
          </Popover>
        );
      },
    },
    {
      title: "Unit",
      dataIndex: "unit_info",
      key: "name",
      render: (item, record) => {
        return (
          <Popover placement="topLeft" content={<RowInfoUI rowData={record} />}>
            <div>{item?.name}</div>
          </Popover>
        );
      },
    },
    {
      title: "Req. Qty.",
      dataIndex: "required_quantity",
      key: "required_quantity",
      render: (text, record) => {
        return (
          <Popover placement="topLeft" content={<RowInfoUI rowData={record} />}>
            <div>{formatNumberWithCommas(record?.required_quantity)}</div>
          </Popover>
        );
      },
    },
    {
      title: (
        <div className="customHeadItem">
          {`${movement?.includes("issue") ? "Issue" : "Receive"} Loose Qty`}
        </div>
      ),
      className: "customHeader",
      dataIndex: "received_loose_quantity",
      key: "received_loose_quantity",
      editable: onEdit,
      render(text, record) {
        return {
          props: {
            style: { background: "#D0D3D4" },
          },
          children: (
            <Form.Item
              style={{
                marginBottom: "0px",
                fontWeight: 700,
                minWidth: "80px",
              }}
            >
              <InputNumber
                value={record?.received_loose_quantity}
                defaultValue={record?.received_loose_quantity}
                disabled={!onEdit}
              />
            </Form.Item>
          ),
        };
      },
    },
    {
      title: <div className="customHeadItem">Balance</div>,
      className: "customHeader",
      dataIndex: "remain_quantity",
      key: "remain_quantity",
      render: (text, record) => {
        return {
          props: {
            style: { background: "#D0D3D4" },
          },
          children: (
            <Popover
              placement="topLeft"
              content={<RowInfoUI rowData={record} />}
            >
              <div>{formatNumberWithCommas(text)}</div>
            </Popover>
          ),
        };
      },
    },
    {
      title: <div>Balance Roll</div>,
      className: "customHeader",
      dataIndex: "balance_shipment_qty",
      key: "balance_shipment_qty",
    },
    {
      title: "Total Roll Rcv.",
      dataIndex: "rcv_shipment_qty",
      key: "rcv_shipment_qty",
    },
    {
      title: "Total Roll Issued",
      dataIndex: "issue_shipment_qty",
      key: "issue_shipment_qty",
    },
    {
      title: "Total Issued Qty",
      dataIndex: "total_issued",
      key: "total_issued",
    },
    {
      title: "Total Received Qty",
      dataIndex: "total_recieved",
      key: "total_recieved",
    },
    {
      title: "Other Info",
      key: "name",
      render: (item, record) => {
        return (
          <Popover
            content={
              <Descriptions
                column={1}
                bordered
                style={{
                  height: 300,
                  width: 350,
                  overflow: "auto",
                }}
              >
                <Descriptions.Item label={"Supplier Name"}>
                  {record?.supplier_info?.name}
                </Descriptions.Item>
                <Descriptions.Item label={"Order Qty."}>
                  {record?.order_quantity
                    ? commaSeparateNumber(record?.order_quantity)
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label={"Day Total"}>
                  {record?.received_total_quantity
                    ? commaSeparateNumber(record?.received_total_quantity)
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label={"Cumulative Total"}>
                  {record?.cumulative_total
                    ? commaSeparateNumber(record?.cumulative_total)
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item
                  label={`${
                    movement?.includes("receive") ? "Receive " : ""
                  }Remaining`}
                >
                  {record?.balance_quantity
                    ? commaSeparateNumber(record?.balance_quantity)
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label={"Free Balance"}>
                  {record?.free_inventory
                    ? commaSeparateNumber(record?.free_inventory)
                    : "N/A"}
                </Descriptions.Item>
              </Descriptions>
            }
            title={
              <div className="">
                <h3>Other Info</h3>
              </div>
            }
          >
            <div className="centered-item-parent">
              <InfoCircleOutlined
                className="cursore-pointer"
                style={{ fontSize: "20px", color: "green" }}
              />
            </div>
          </Popover>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render(text, record) {
        return (
          <div>
            <Tooltip placement="topLeft" title="Add">
              <Button
                onClick={() => {
                  onAssign(record);
                }}
                style={{ width: "100%" }}
                icon={<PlusOutlined />}
                disabled={!hasPermission([INVENTORY_CREATE_PERMISSION])}
              />
            </Tooltip>
            <Tooltip placement="bottomLeft" title="Update">
              <Button
                onClick={() => {
                  onAssign(record, "update");
                }}
                style={{ width: "100%" }}
                icon={<EditOutlined />}
                disabled={!hasPermission([INVENTORY_EDIT_PERMISSION])}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleSave = (row, val) => {
    const newData = [...BOMDetails];
    const index = BOMDetails.findIndex((item) => row.id === item.id);
    newData[index] = row;
    newData[index]["received_loose_quantity"] = val?.received_loose_quantity
      ? parseInt(val?.received_loose_quantity)
      : parseInt(row?.received_loose_quantity);
    newData[index]["received_intake_quantity"] = val?.received_intake_quantity
      ? parseInt(val?.received_intake_quantity)
      : parseInt(row?.received_intake_quantity);
    newData[index]["reference_no"] = val?.reference_no;
    newData[index]["shipment_qty"] = val?.shipment_qty ? val?.shipment_qty : 0;
    newData[index]["shipment_unit"] = val?.shipment_unit
      ? val?.shipment_unit
      : "";
    newData[index]["challan_id"] = val?.challan_id;
    newData[index]["invoice_id"] = val?.invoice_id;
    newData[index]["requisition_no"] = val?.requisition_no;

    setInventoryItems(newData);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const filteredDefaultColumns = (defaultColumns) => {
    if (!defaultColumns) {
      return [];
    }

    const allowedMovements = [
      "return_from_cutting",
      "return_from_sewing",
      "return_from_folding",
      "return_from_packing",
    ];

    if (allowedMovements?.includes(movement)) {
      return defaultColumns;
    } else {
      return defaultColumns.filter(
        (item) => item.key !== "received_loose_quantity",
      );
    }
  };

  const columns = filteredDefaultColumns(defaultColumns)?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const onDrawerClose = () => {
    setSelectedRecord(null);
    setTempRowData([]);
    setOpenDrawer(false);
    setIsItemUpdate(false);
    entryForm.resetFields();
    getItemDetailsData();
  };

  const onFinishTrackInput = async (values) => {
    const entryValue = entryForm.getFieldsValue();
    const formValues = form.getFieldsValue();
    const movementType = getMovementType(movement);
    const selectedMaterialId = entryForm.getFieldValue("selected_material_id");
    
    if(selectedRecord && !selectedMaterialId && isItemUpdate) {
      HandleNotification('error', 'bottomRight', 'Please select a time!', null);
      return;
    }

    const trackDetails =
      isArrayAndHasValue(values?.tracks) &&
      values?.tracks?.map((item) => {
        return {
          track_id: item?.id || 0,
          history_id: isItemUpdate ? item?.history_id : 0,
          bin_id: item?.bin_id,
          shipment_qty: isItemUpdate
            ? item?.total_shipment
            : item?.shipment_qty,
          total_quantity: isItemUpdate
            ? item?.total_balance
            : item?.total_quantity,
          is_delete: isItemUpdate ? item?.is_delete || 0 : 0,
        };
      });

    const bodyData = {
      balance_quantity: selectedRecord?.balance_quantity
      ? Number(selectedRecord?.balance_quantity)
      : 0,
      buying_office_id: styleDetails?.style?.buying_office_info?.id,
      entry_date: formValues?.entry_date,
      internal_po: formValues?.internal_po || null,
      item_category_id: selectedRecord?.item_category_info?.id,
      item_id: selectedRecord?.item_id,
      movement: movementType,
      movement_type:
        formValues?.movement_type_incoming ||
        formValues?.movement_type_outgoing,
      original_po: formValues?.original_po || null,
      received_intake_quantity: entryValue?.receive_quantity,
      received_loose_quantity: selectedRecord?.received_loose_quantity
        ? Number(selectedRecord?.received_loose_quantity)
        : 0,
      reference_no: selectedRecord?.reference_no,
      required_quantity: selectedRecord?.required_quantity
        ? Number(selectedRecord?.required_quantity)
        : 0,
      requisition_no: selectedRecord?.requisition_no,
      sales_contract_id:
      styleDetails?.style?.active_sales_contract_map_info?.active_sales_contract_info?.id,
      shipment_qty: entryValue?.roll_quantity || 0,
      shipment_unit: "Roll",
      style_id: styleDetails?.style?.id,
      sub_style_id: selectedRecord?.sub_style_map?.id || null,
      color_id: selectedRecord?.color_info?.id || null,
      supplier_id: selectedRecord?.supplier_id, 
      unit_id: selectedRecord?.unit_info?.id,
      convertion_unit_id: 1,
      convertion_rate: 1,
      use_fabric_interlining_store: "Yes",
      inventory_detail_id: isItemUpdate ? 1 : 0,
      challan_id: formValues?.challan_id,
      invoice_id: formValues?.invoice_id,
      sub_contract_factory_id: styleDetails?.style?.sub_contract_factory_id,
      track_details: isArrayAndHasValue(trackDetails) ? trackDetails : null,
    };

    if (bodyData) {
      const response = await postData(
        isItemUpdate ? `${RAW_MATERIAL_UPDATE}/${selectedMaterialId}` : RAW_MATERIAL_ADD,
        bodyData,
      );

      if (response && response?.code === 200) {
        HandleNotification(
          "success",
          "bottomRight",
          `Track ${isItemUpdate ? "Updated" : "Assigned"} Successfully!`,
          null,
        );
        onDrawerClose();
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Something went wrong!",
          null,
        );
      }
    }
  };

  const onSearchTable = (values) => {
    let filteredData = [];
    let categoryFilteredData = [];
    let nameFilteredData = [];

    if (values) {
      if (values?.item_type_search_input) {
        categoryFilteredData = BOMDetails.filter((item) =>
          item?.item_category_info?.name
            ?.toLowerCase()
            ?.includes(values?.item_type_search_input?.toLowerCase()),
        );
      }
      if (values?.item_name_search_input) {
        nameFilteredData = BOMDetails.filter((item) =>
          item?.item_info?.name
            ?.toLowerCase()
            ?.includes(values?.item_name_search_input?.toLowerCase()),
        );
      }
    }

    filteredData = [...new Set([...categoryFilteredData, ...nameFilteredData])];

    if (filteredData.length > 0) {
      setBOMDetails(filteredData);
    }
  };

  const onResetTableSearch = () => {
    setBOMDetails(selectedBOM);
    tableItemSearchForm.resetFields();
  };

  const onRemoveItemFromList = async (item, formItemRemove) => {
    const trackList = itemTrackingForm.getFieldValue("tracks");
    const trackId = trackList[item?.key]?.id;
    const foundTrack = trackList?.find((item) => item?.id === trackId);
    foundTrack["is_delete"] = 1;
    
    itemTrackingForm.setFieldValue("tracks", trackList);
  };

  const RowInfoUI = ({ rowData }) => {
    return (
      <div style={{ padding: "10px" }}>
        <div>
          <strong>Item Type:</strong>{" "}
          {rowData?.item_category_info?.name || "N/A"}
        </div>
        <div>
          <strong>Item Name:</strong> {rowData?.item_info?.name || "N/A"}
        </div>
        <div>
          <strong>Color:</strong> {rowData?.color_info?.name || "N/A"}
        </div>
        <div>
          <strong>Substyle:</strong>{" "}
          {rowData?.sub_style_map?.sub_style_no || "N/A"}
        </div>
        <div>
          <strong>Order Qty:</strong>{" "}
          {rowData?.order_quantity
            ? commaSeparateNumber(rowData?.order_quantity)
            : "N/A"}
        </div>
        <div>
          <strong>Required Qty:</strong>{" "}
          {rowData?.required_quantity
            ? commaSeparateNumber(rowData?.required_quantity)
            : "N/A"}
        </div>
        <div>
          <strong>Unit:</strong> {rowData?.unit_info?.name || "N/A"}
        </div>
      </div>
    );
  };

  const calculateTotal = () => {
    const itemTrackingFormValues = itemTrackingForm.getFieldsValue();
    const totalRollQty = itemTrackingFormValues?.tracks?.reduce(
      (result, item) => {
        return result + Number(item?.shipment_qty);
      },
      0,
    );
    const totalReceiveQty = itemTrackingFormValues?.tracks?.reduce(
      (result, item) => {
        return result + Number(item?.total_quantity);
      },
      0,
    );

    // Set total values to form
    entryForm.setFieldsValue({
      roll_quantity: totalRollQty,
      receive_quantity: totalReceiveQty,
    });
  };

  return (
    <div className="inventoryTable" style={{ marginTop: 25 }}>
      <Typography.Title style={{ fontSize: 16 }}>Search Item</Typography.Title>
      <Form
        form={tableItemSearchForm}
        layout="vertical"
        onFinish={onSearchTable}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item name="item_type_search_input" label="Item Type">
              <Input size="small" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="item_name_search_input" label="Item Name">
              <Input size="small" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <div className="centered-item-parent fullHeightWidth">
              <Button
                style={{ width: "100%" }}
                htmlType="submit"
                type="primary"
                size="small"
              >
                Search
              </Button>
            </div>
          </Col>
          <Col span={4}>
            <div className="centered-item-parent fullHeightWidth">
              <Button
                style={{ width: "100%" }}
                onClick={onResetTableSearch}
                size="small"
              >
                Reset
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      {BOMDetails?.length > 0 ? (
        <>
          <Typography.Title style={{ fontSize: 16 }}>
            Item Details
          </Typography.Title>
          <Table
            scroll={{
              x: 1600,
              y: 400,
            }}
            pagination={false}
            loading={loading}
            components={components}
            rowClassName={() => "editable-row"}
            bordered
            dataSource={BOMDetails}
            columns={columns}
          />
        </>
      ) : (
        <></>
      )}
      <Drawer
        title="Item Tracking"
        placement="right"
        onClose={onDrawerClose}
        open={openDrawer}
        width={1000}
      >
        <div>
          <Space direction="vertical">
            <Card>
              <Typography.Title style={{ fontSize: 16 }}>
                {isItemUpdate ? `Update Form` : `Entry Form`}
              </Typography.Title>
              <Form layout="vertical" form={entryForm}>
                <EntryForm
                  selectedRecord={selectedRecord}
                  styleDetails={styleDetails}
                  movement={movement}
                  inventoryFormValues={inventoryFormValues}
                  entryForm={entryForm}
                  isItemUpdate={isItemUpdate}
                  form={form}
                  itemTrackingForm={itemTrackingForm}
                />
              </Form>
            </Card>

            <Card>
              <Typography.Title style={{ fontSize: 16 }}>
                Warehouse Tracking
              </Typography.Title>
              <Form
                layout="vertical"
                form={itemTrackingForm}
                onFinish={onFinishTrackInput}
              >
                <WarehouseForm
                  onWarehouseSelect={onWarehouseSelect}
                  warehouseList={warehouseList}
                  onRackSelect={onRackSelect}
                  getRackList={getRackList}
                  getBinList={getBinList}
                  selectedRecord={selectedRecord}
                  onRemoveItemFromList={onRemoveItemFromList}
                  isItemUpdate={isItemUpdate}
                  calculateTotal={calculateTotal}
                  trackList={tracks_watch}
                />

                <div className="centered-item-parent">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ width: "40%", marginTop: "20px" }}
                    size="small"
                  >
                    {isItemUpdate ? "Update" : "Submit"}
                  </Button>
                </div>
              </Form>
            </Card>
          </Space>
        </div>
      </Drawer>
    </div>
  );
};
export default BOMTable;
