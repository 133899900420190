import React from "react";
import { Card, Table } from "antd";
import { Line } from "@ant-design/charts";

const dataSource = [
  {
    key: "1",
    sewingLine: "Line #01",
    lineChief: "RAFIQUL ISLAM",
    lineAttendance: 1035,
    prodPc: 14105,
    avgCMUSD: 0.88,
    incomeCMUSD: 12390,
    incomeCMTaka: 14045779,
    expenditureTaka: 2544277,
    netProfitTaka: 1094699,
    profitabilityRank: "9th",
  },
  {
    key: "2",
    sewingLine: "Line #02",
    lineChief: "SHERAJ (ACT)",
    lineAttendance: 975,
    prodPc: 19265,
    avgCMUSD: 0.81,
    incomeCMUSD: 15104,
    incomeCMTaka: 4067744,
    expenditureTaka: 2396254,
    netProfitTaka: 1675010,
    profitabilityRank: "1st",
  },
  // Add the remaining rows here based on the image data
];

const columns = [
  {
    title: "Sewing Line",
    dataIndex: "sewingLine",
    key: "sewingLine",
  },
  {
    title: "Line Chief",
    dataIndex: "lineChief",
    key: "lineChief",
  },
  {
    title: "Line Attendance",
    dataIndex: "lineAttendance",
    key: "lineAttendance",
  },
  {
    title: "Up to date prod/pc",
    dataIndex: "prodPc",
    key: "prodPc",
  },
  {
    title: "Avg CM/USD",
    dataIndex: "avgCMUSD",
    key: "avgCMUSD",
  },
  {
    title: "Income CM/USD",
    dataIndex: "incomeCMUSD",
    key: "incomeCMUSD",
  },
  {
    title: "Income CM/Taka",
    dataIndex: "incomeCMTaka",
    key: "incomeCMTaka",
  },
  {
    title: "Expenditure Taka",
    dataIndex: "expenditureTaka",
    key: "expenditureTaka",
  },
  {
    title: "Up to date Est. Net Profit/taka",
    dataIndex: "netProfitTaka",
    key: "netProfitTaka",
  },
  {
    title: "Ranking on Profitability",
    dataIndex: "profitabilityRank",
    key: "profitabilityRank",
  },
];
const data = [
  { line: "Line # 01", profit: 10 },
  { line: "Line # 08", profit: -60 },
  { line: "Line # 03", profit: 30 },
  { line: "Line # 04", profit: 40 },
  { line: "Line # 05", profit: 50 },
  { line: "Line # 06", profit: 60 },
  { line: "Line # 07", profit: 0 },
  { line: "Line # 09", profit: -50 },
  { line: "Line # 02", profit: 20 },
  { line: "Line # 10", profit: -40 },
  { line: "Line # 11", profit: -30 },
  { line: "Line # 12", profit: -20 },
];

const config = {
  data,
  xField: "line",
  yField: "profit",
  point: {
    size: 6,
    shape: "circle",
    style: {
      fill: "magenta",
      stroke: "#00bfff",
      lineWidth: 2,
    },
  },
  lineStyle: {
    stroke: "#00bfff",
    lineWidth: 2,
  },
  smooth: true,
  xAxis: {
    label: {
      autoRotate: false,
      style: { fill: "#fff" },
    },
    title: {
      text: "Lines",
      style: { fill: "#fff" },
    },
  },
  yAxis: {
    label: {
      style: { fill: "#fff" },
    },
    title: {
      text: "Profit (Taka)",
      style: { fill: "#fff" },
    },
  },
  tooltip: {
    formatter: (datum) => ({
      name: "Profit",
      value: `${datum.profit} Taka`,
    }),
  },
  background: {
    fill: "#333",
  },
  theme: {
    backgroundColor: "#333",
  },
};

const UpToDate = () => {
  return (
    <Card
      title="Today's production, income & profit for Line wise:"
      bordered={false}
    >
      <Table dataSource={dataSource} columns={columns} pagination={false} />
      <div
        style={{
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
      >
        <h3
          style={{
            color: "#00bfff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Today's Est. Net Profit/taka
        </h3>
        <Line {...config} />
      </div>
    </Card>
  );
};

export default UpToDate;
