import { Col, Form, Row, Input, DatePicker } from "antd";

const HROTExpenseDataFilter = ({ initialTableData }) => {
  return (
    <>
      <Col span={4}>
        <Form.Item label="Date" name="date">
          <DatePicker
            placeholder="Date"
            size="small"
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Name" name="name">
          <Input placeholder="Name" size="small" />
        </Form.Item>
      </Col>
      <Col span={4}>
        <Form.Item label="Card No" name="card_no">
          <Input placeholder="Card No" size="small" />
        </Form.Item>
      </Col>
    </>
  );
};

export default HROTExpenseDataFilter;
