import { useCallback, useEffect, useMemo, useState } from "react";
import {
  DeleteTwoTone,
  EditTwoTone,
  EyeOutlined,
  HighlightOutlined,
  CopyOutlined,
  PaperClipOutlined,
} from "@ant-design/icons";
import {
  message,
  Table,
  Dropdown,
  Menu,
  Popconfirm,
  Button,
  Space,
  Upload,
  Row,
  Col,
  Typography,
  Modal,
  Form,
  Input,
  Select,
  Switch,
} from "antd";
import AppContent from "../../common/AppContent";
import AppPageHeader from "../../common/PageHeader";
import ExtraContent from "../Configuration/CommonComponents/ExtraContent";
import FilterComponent from "../Configuration/CommonComponents/FilterComponent";
import {
  getData,
  deleteData,
  postData,
  putData,
} from "../../apiServices/common";
import { OB_LIST, OB_DELETE, OB_CLONE } from "../../apiServices/API_ENDPOINTS";
import { useSelector } from "react-redux";
import { hasPermission, renderSubstyleName } from "../../utils/functions";
import {
  OB_CREATE_PERMISSION,
  OB_EDIT_PERMISSION,
  OB_DELETE_PERMISSION,
  OB_VIEW_PERMISSION,
  OB_CLONE_PERMISSION,
} from "../../routes/permissions";
import { useNavigate } from "react-router-dom";
import { render } from "@testing-library/react";

const OperationBulletin = () => {
  // States
  const [refresh, setRefresh] = useState(0);
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilter, setShowFilter] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  // Redux
  const filterData = useSelector(({ filter }) => filter.filterData);

  // Router
  const navigate = useNavigate();

  // Antd
  const { Title } = Typography;

  const getAllOb = useCallback(
    async (filterValues) => {
      setLoading(true);

      const query = `${OB_LIST}`;
      const bodyData = {
        page: currentPage || 1,
        per_page: 10,
        ...filterValues,
      };

      const response = await getData(query, false, bodyData);
      if (response?.status === 200) {
        setLoading(false);
        setMetaData({
          defaultPageSize: response?.data?.data?.per_page,
          current: response?.data?.data?.current_page,
          defaultCurrent: response?.data?.data?.current_page,
          total: response?.data?.data?.total,
        });
        setTableData(response?.data?.data?.data);
      } else {
        setLoading(false);
        message.error("Error getting OB data");
      }
    },
    [currentPage],
  );

  // Effects
  useEffect(() => {
    getAllOb(filterData);
  }, [refresh, filterData, getAllOb]);

  const refetch = () => {
    setRefresh(refresh + 1);
  };

  const deleteSelectedOb = async (id) => {
    setLoading(true);
    const response = await deleteData(`${OB_DELETE}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("OB Deleted Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const cloneSelectedOb = async (id) => {
    setLoading(true);
    const response = await postData(`${OB_CLONE}/${id}`);

    if (response && response?.code === 200) {
      setLoading(false);
      message.success("OB Cloned Successfully");
      refetch();
    } else {
      setLoading(false);
      message.error("Something went wrong");
    }
  };

  const changePage = (newPage) => {
    setCurrentPage(newPage);
    refetch();
  };

  const openForAdd = () => {
    navigate(`/merchandising/operation-bulletin-entry`);
  };

  const openForEdit = (id) => {
    navigate(`/merchandising/operation-bulletin/edit/${id}`);
  };

  const openForUpdateManpower = (id) => {
    navigate(`/merchandising/operation-bulletin/update-manpower/${id}`);
  };

  const openForShow = (id) => {
    navigate(`/merchandising/operation-bulletin/show/${id}`);
  };

  const tableColumns = [
    {
      title: "Inquiry No.",
      dataIndex: ["inquiry_details", "inquiry_no"],
      key: "inquiry_no",
      align: "center",
      render: (value, record) => {
        return <span>{record?.inquiry_details?.inquiry_no || "-"}</span>;
      },
    },
    {
      title: "Style",
      dataIndex: ["style", "style_no"],
      key: "style",
      align: "center",
      render: (_, record) => {
        const styleNo = record?.style?.style_no || "-";
        const document = record?.style?.latest_style_document?.document;

        return (
          <div>
            <span>{styleNo}</span>&nbsp;&nbsp;&nbsp;&nbsp;
            {document && (
              <a
                href={`${baseUrl}/${document}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ fontSize: "16px", color: "#1890ff" }}
              >
                <PaperClipOutlined />
              </a>
            )}
          </div>
        );
      },
    },
    {
      title: "Substyle",
      dataIndex: ["sub_style_info"],
      key: "sub_style_info",
      align: "center",
      render: (value, record) => {
        return <span>{renderSubstyleName(value)}</span>;
      },
    },
    {
      title: "Sales Contract",
      dataIndex: ["sales_contract", "reference_no"],
      key: "sales_contract",
      align: "center",
      render: (value, record) => {
        return <span>{record?.sales_contract?.reference_no || "-"}</span>;
      },
    },
    {
      title: "Buyer",
      dataIndex: ["buyer", "name"],
      key: "buyer",
    },
    {
      title: "Total SAM",
      dataIndex: ["total_sam"],
      key: "total_sam",
      align: "center",
    },
    {
      title: "Total Updated SAM",
      dataIndex: ["total_updated_sam"],
      key: "total_sam",
      align: "center",
    },
    {
      title: "Manpower Updated",
      dataIndex: ["manpower_updated_status"],
      key: "manpower_updated_status",
      align: "center",
      render: (_, record) => {
        return (
          <span>{record?.manpower_updated_status == 1 ? "Yes" : "No"}</span>
        );
      },
    },
    {
      title: "CM Value Status",
      dataIndex: ["inquiry_details", "cm_status"],
      key: "cm_status",
      align: "center",
      render: (value, record) => {
        return <span>{value === 1 ? "Approved" : "Pending"}</span>;
      },
    },
    // {
    //   title: "Created For",
    //   dataIndex: ["created_for"],
    //   key: "created_for",
    //   align: "center",
    // },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_, record) => {
        return (
          <Dropdown.Button
            overlay={
              <Menu
                items={[
                  {
                    label: (
                      <Popconfirm
                        title="Are you sure you want to delete?"
                        onConfirm={() => {
                          deleteSelectedOb(record?.id);
                        }}
                        style={{ width: "100%" }}
                      >
                        <div style={{ width: "100%" }}>
                          <span>
                            <DeleteTwoTone />
                          </span>
                          <span
                            style={{
                              color: "red",
                              marginLeft: 5,
                            }}
                          >
                            Delete
                          </span>
                        </div>
                      </Popconfirm>
                    ),
                    key: "1",
                    disabled: !hasPermission([OB_DELETE_PERMISSION]),
                  },
                  {
                    label: (
                      <div
                        onClick={() => {
                          openForEdit(record?.id);
                        }}
                      >
                        <span>
                          <EditTwoTone />
                        </span>
                        <span type="button" style={{ marginLeft: 5 }}>
                          Edit
                        </span>
                      </div>
                    ),
                    key: "2",
                    disabled: !hasPermission([OB_EDIT_PERMISSION]),
                  },
                  // {
                  //   label: (
                  //     <Popconfirm
                  //       title="Are you sure you want to Clone?"
                  //       onConfirm={() => {
                  //         cloneSelectedOb(record?.id);
                  //       }}
                  //     >
                  //       <span>
                  //         <CopyOutlined />
                  //       </span>
                  //       <span
                  //         style={{
                  //           marginLeft: 5,
                  //         }}
                  //       >
                  //         Clone
                  //       </span>
                  //     </Popconfirm>
                  //   ),
                  //   key: "1",
                  //   disabled: !hasPermission([OB_CLONE_PERMISSION]) || !record?.inquiry_id,
                  // },
                  {
                    label: (
                      <>
                        <span>
                          <HighlightOutlined />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openForUpdateManpower(record?.id);
                          }}
                          disabled={!hasPermission([OB_EDIT_PERMISSION])}
                        >
                          Update Manpower
                        </span>
                      </>
                    ),
                    key: "3",
                    disabled: !hasPermission([OB_EDIT_PERMISSION]),
                  },
                  {
                    label: (
                      <>
                        <span>
                          <EyeOutlined />
                        </span>
                        <span
                          type="button"
                          style={{ marginLeft: 5 }}
                          onClick={() => {
                            openForShow(record?.id);
                          }}
                        >
                          Show
                        </span>
                      </>
                    ),
                    key: "4",
                    disabled: !hasPermission([OB_VIEW_PERMISSION]),
                  },
                ]}
              />
            }
          ></Dropdown.Button>
        );
      },
    },
  ];

  const memoizedCustomFilter = useMemo(
    () => (
      <FilterComponent
        tableData={tableData}
        filterAPI={getAllOb}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        filterName="ob_filter"
      />
    ),
    [showFilter],
  );

  return (
    <>
      <AppContent
        header={
          <AppPageHeader
            extra={
              <ExtraContent
                refresh={refresh}
                setRefresh={setRefresh}
                handleAddItemOpen={openForAdd}
                disabled={!hasPermission([OB_CREATE_PERMISSION])}
                addButtonText={
                  hasPermission([OB_CREATE_PERMISSION])
                    ? "Add New"
                    : "No Permission"
                }
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            }
            title={<Title level={5}>Operation Bulletin</Title>}
          />
        }
        content={
          <>
            {memoizedCustomFilter}

            <div className="table" style={{ marginTop: 10 }}>
              <Table
                loading={loading}
                dataSource={tableData}
                columns={tableColumns}
                bordered
                pagination={{
                  showSizeChanger: false,
                  defaultPageSize: metaData?.defaultPageSize,
                  current: metaData?.current,
                  onChange: (newPage) => {
                    changePage(newPage);
                  },
                  defaultCurrent: metaData?.defaultCurrent,
                  total: metaData?.total,
                }}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default OperationBulletin;
