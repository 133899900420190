import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
} from "antd";
import DrawerFormSize from "./DrawerFormSize";
import HandleNotification from "common/Notification";

const DrawerForm = ({
  props,
  open = false,
  setOpen,
  useBothStylePo,
  setPoDetailsData,
  addNewRow,
}) => {
  const {
    view,
    poDetailsData,
    purchesList,
    unique_id,
    setOriginalPo,
    setPoMapId,
    setInternalPo,
    setPoMapIdByOriginalPO,
    onChangePoDetailsValue,
    setDeliveryDate,
    styleList,
    styleWiseColorList,
    getSubStyle,
    isArrayAndHasValue,
    dynamicSizeList,
    updateSizeQuantity,
    commaSeparateNumber,
    form,
    aIsEdit,
    setAIsEdit,
    setUniqueId,
  } = props;

  const { Option } = Select;

  const onClose = () => {
    // Only if add
    if (!aIsEdit) {
      const poDetailsDataCopy = [...poDetailsData];
      poDetailsDataCopy.splice(unique_id, 1);
      setPoDetailsData(poDetailsDataCopy);
    }

    setOpen(false);
    form.resetFields();
  };

  const checkOrderQuantityMoreThan = (list) => {
    // get same style
    const sameStyle = list.filter(
      (item) => item.style_id === list[unique_id].style_id,
    );

    // check if order quantity more than
    const orderQuantity = parseInt(list[unique_id].order_quantity);

    const totalQuantity = sameStyle.reduce((acc, item) => {
      return (
        acc +
        item.sizes.reduce((acc, size) => {
          return acc + size.sales_order_quantity;
        }, 0)
      );
    }, 0);

    if (totalQuantity > orderQuantity) {
      message.error("Order Quantity is more than Style Quantity");
      return true;
    }
  };

  const onFinish = (value) => {
    if (checkOrderQuantityMoreThan(poDetailsData)) {
      return;
    }
    // poDetailsData in array style, sub style, color  id not be same other row
    const isDuplicate = poDetailsData
      .map((item, index, items) => {
        return `${item?.style_id}_${item?.sub_style_info?.id}_${item?.color_id}`;
      })
      .some((item, index, items) => items.indexOf(item) !== index);

    if (isDuplicate) {
      HandleNotification(
        "error",
        "bottomRight",
        "Duplicate style, sub style, or color found",
        null,
      );
      return;
    }

    // setAIsEdit(false);
    // setUniqueId(0);
    // form.resetFields();
    // setOpen(false);
    if (aIsEdit) {
      setOpen(false);
      setAIsEdit(false);
      setUniqueId(0);
    } else {
      addNewRow();
    }

    form.resetFields();
  };

  const filterOption = (input, option) => {
    return (
      option?.children != null &&
      option.children.toLowerCase().includes(input.toLowerCase())
    );
  };

  const isSubstyleExists = () => {
    const substyles = poDetailsData?.[unique_id]?.style?.sub_style_map;
    const isExists =
      isArrayAndHasValue(substyles) &&
      substyles?.some((item) => item?.sub_style_no);
    return isExists || false;
  };

  return (
    <>
      <Drawer
        title="Create a new PO Style"
        width={720}
        onClose={onClose}
        open={open}
        styles={{ body: { paddingBottom: 80 } }}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={[8, 8]}>
            <Col
              className="gutter-row"
              span={useBothStylePo === "No" ? 0 : 12}
              hidden={useBothStylePo === "No"}
            >
              <Form.Item
                label="Internal PO"
                name="internal_po"
                rules={[
                  {
                    required: useBothStylePo === "No" ? false : true,
                    message: "Please Select Internal PO!",
                  },
                ]}
                style={{ marginBottom: "unset" }}
              >
                <Select
                  className="w-100"
                  showSearch
                  disabled={view}
                  dropdownStyle={{ minWidth: 250 }}
                  optionFilterProp="children"
                  placeholder="Select an Internal PO"
                  size="small"
                  onChange={(value) => {
                    purchesList?.forEach((item) => {
                      if (item.internal_po === value) {
                        form.setFieldsValue({
                          original_po: item.original_po,
                        });
                      }
                    });
                    onChangePoDetailsValue(value, unique_id, "internal_po");
                    setOriginalPo(value, unique_id, "original_po");
                    setDeliveryDate(value, unique_id, "from_internal_po");
                    setPoMapId(value, unique_id, "id");
                  }}
                  filterOption={(input, option) => filterOption(input, option)}
                >
                  {purchesList?.length &&
                    purchesList.map((sup, index) => (
                      <Option
                        value={sup.internal_po || undefined}
                        key={`internal_po_key_${index}`}
                      >
                        {sup.internal_po}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              className="gutter-row"
              span={useBothStylePo === "No" ? 0 : 12}
              hidden={useBothStylePo === "No"}
            >
              <Form.Item
                label={"Original PO"}
                name={"original_po"}
                rules={[
                  {
                    required: useBothStylePo === "No" ? false : true,
                    message: "Please Select Original PO!",
                  },
                ]}
                style={{ marginBottom: "unset" }}
              >
                <Select
                  className="w-100"
                  showSearch
                  disabled={view}
                  dropdownStyle={{ minWidth: 250 }}
                  optionFilterProp="children"
                  placeholder="Select an Original PO"
                  size="small"
                  onChange={(value) => {
                    purchesList?.forEach((item) => {
                      if (item.original_po === value) {
                        form.setFieldsValue({
                          internal_po: item.internal_po,
                        });
                      }
                    });
                    onChangePoDetailsValue(value, unique_id, "original_po");
                    setInternalPo(value, unique_id, "internal_po");
                    setDeliveryDate(value, unique_id, "from_original_po");
                    setPoMapIdByOriginalPO(value, unique_id, "id");
                  }}
                  filterOption={(input, option) => filterOption(input, option)}
                >
                  {purchesList?.length &&
                    purchesList.map((sup, index) => {
                      return (
                        <Option
                          value={sup?.original_po || undefined}
                          key={`original_po_key_${index}`}
                        >
                          {sup?.original_po}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Please Select Style NO!",
                  },
                ]}
                style={{ marginBottom: "unset" }}
                label={"Style"}
                name="style_id"
              >
                <Select
                  dropdownStyle={{ minWidth: 350 }}
                  onChange={(value) => {
                    const foundStyle = styleList?.find(
                      (item) => item?.style_id === value,
                    );
                    const foundStyleInfo = foundStyle?.style_info;

                    onChangePoDetailsValue(
                      foundStyle?.order_quantity,
                      unique_id,
                      "order_quantity",
                    );

                    onChangePoDetailsValue(foundStyleInfo, unique_id, "style");
                    onChangePoDetailsValue(
                      foundStyleInfo?.id,
                      unique_id,
                      "style_id",
                    );
                    // all time first color list select
                    onChangePoDetailsValue(
                      foundStyleInfo?.color_map[0],
                      unique_id,
                      "color",
                    );
                    onChangePoDetailsValue(
                      foundStyleInfo?.color_map[0]?.color_id,
                      unique_id,
                      "color_id",
                    );
                    onChangePoDetailsValue(
                      foundStyleInfo?.sub_style_map?.[0],
                      unique_id,
                      "sub_style_info",
                    );

                    form.setFieldsValue({
                      color_id: foundStyleInfo?.color_map[0]?.color_id,
                      sub_style_id: foundStyleInfo?.sub_style_map[0]?.id,
                      brand: foundStyleInfo?.brand_info?.name,
                    });
                    styleWiseColorList(value, unique_id);
                  }}
                  className="w-100"
                  showSearch
                  placeholder="Select a style"
                  optionFilterProp="children"
                  disabled={view}
                  size="small"
                >
                  {isArrayAndHasValue(styleList) &&
                    styleList.map((styleItem, index) => {
                      return (
                        <Option
                          value={
                            styleItem?.style_id ||
                            styleItem?.style_info?.id ||
                            undefined
                          }
                          key={`style_id_key_${index}`}
                        >
                          {`${styleItem?.style_info?.style_no || ""}${
                            styleItem?.description ? ":" : ""
                          }${styleItem?.description || ""}`}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={isSubstyleExists() ? 12 : 0}>
              <Form.Item
                name="sub_style_id"
                rules={[
                  {
                    required: false,
                    message: "Please select substyle",
                  },
                ]}
                style={{ marginBottom: "unset" }}
                label="Sub Style"
                hidden={isSubstyleExists() ? false : true}
              >
                <Select
                  name="sub_style_id"
                  dropdownStyle={{ minWidth: 350 }}
                  onChange={(value) => {
                    const foundSubStyleInfo = poDetailsData?.[
                      unique_id
                    ]?.style?.sub_style_map?.find(
                      (subStyle) => subStyle?.id === value,
                    );

                    onChangePoDetailsValue(
                      foundSubStyleInfo,
                      unique_id,
                      "sub_style_info",
                    );
                    // onChangePoDetailsValue(
                    //   foundSubStyleInfo,
                    //   unique_id,
                    //   "color",
                    // );
                    // onChangePoDetailsValue(
                    //   foundSubStyleInfo?.color_id,
                    //   unique_id,
                    //   "color_id",
                    // );
                    // form.setFieldsValue({
                    //   color_id: foundSubStyleInfo?.color_id,
                    //   sub_style_id: foundSubStyleInfo?.id,
                    // });
                  }}
                  className="w-100"
                  showSearch
                  placeholder="Select Substyle"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (option?.children != null) {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }
                  }}
                  disabled={view}
                  size="small"
                >
                  {isArrayAndHasValue(
                    poDetailsData?.[unique_id]?.style?.sub_style_map,
                  ) &&
                    poDetailsData?.[unique_id]?.style?.sub_style_map?.map(
                      (subStyle, index) => {
                        return (
                          <Option
                            value={subStyle?.id || undefined}
                            key={subStyle?.id}
                          >
                            {subStyle?.sub_style_no ||
                              subStyle?.color_info?.name}
                          </Option>
                        );
                      },
                    )}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name={"color_id"}
                rules={[
                  {
                    required: false,
                    message: "Please Select Color",
                  },
                ]}
                style={{ marginBottom: "unset" }}
                label={"Color"}
              >
                <Select
                  name={"color_id"}
                  dropdownStyle={{ minWidth: 350 }}
                  onChange={(value) => {
                    const foundColorInfo = poDetailsData?.[
                      unique_id
                    ]?.style?.color_map?.find(
                      (colorItem) => colorItem?.color_info?.id === value,
                    );

                    // onChangePoDetailsValue(
                    //   foundColorInfo.id,
                    //   unique_id,
                    //   "sub_style_id",
                    // );
                    onChangePoDetailsValue(foundColorInfo, unique_id, "color");
                    onChangePoDetailsValue(
                      foundColorInfo?.color_info?.id,
                      unique_id,
                      "color_id",
                    );

                    // form?.setFieldsValue({
                    //   sub_style_id: foundColorInfo?.id,
                    //   color_id: foundColorInfo?.color_info?.id,
                    // });

                    // getSubStyle(unique_id, foundColorInfo?.color_id);
                  }}
                  className="w-100"
                  showSearch
                  placeholder="Select an Style No"
                  optionFilterProp="children"
                  filterOption={(input, option) => {
                    if (option?.children != null) {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }
                  }}
                  disabled={view}
                  size="small"
                >
                  {isArrayAndHasValue(
                    poDetailsData?.[unique_id]?.style?.color_map,
                  ) &&
                    poDetailsData?.[unique_id]?.style?.color_map?.map(
                      (colorItem, index) => {
                        return (
                          <Option
                            value={colorItem?.color_info?.id || undefined}
                            key={colorItem?.color_info?.id}
                          >
                            {colorItem?.color_info?.name}
                          </Option>
                        );
                      },
                    )}
                </Select>
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                name="brand"
                style={{ marginBottom: "unset" }}
                label="Brand"
              >
                <Input size="small" disabled />
              </Form.Item>
            </Col>
            <Col className="gutter-row" span={12}>
              <Form.Item
                label={"Extra Cutting"}
                name={`extra_cutting`}
                rules={[
                  { required: true, message: "Please Insert Extra Cutting!" },
                ]}
                style={{ marginBottom: "unset" }}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={view}
                  precision={2}
                  size="small"
                  onChange={(value) => {
                    let context = "extra_cutting";
                    onChangePoDetailsValue(value, unique_id, context);
                  }}
                  placeholder="Extra Cutting (Required)"
                />
              </Form.Item>
            </Col>
          </Row>
          {isArrayAndHasValue(dynamicSizeList) && (
            <DrawerFormSize
              view={view}
              unique_id={unique_id}
              dynamicSizeList={dynamicSizeList}
              updateSizeQuantity={updateSizeQuantity}
              commaSeparateNumber={commaSeparateNumber}
            />
          )}
          <Row gutter={8} className="mt-4">
            <Col span={12}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit"
                size="small"
              >
                {aIsEdit ? `Update` : `Add`}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                style={{ width: "100%" }}
                type="danger"
                size="small"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default DrawerForm;
