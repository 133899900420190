import React from "react";
import Matrix11by11 from "./Matrix11by11";
import Matrix16by16 from "./Matrix16by16";
import { color } from "./style";

export default function Matrix({ task }) {
  return task.details?.length > 0
    ? task.details.map((detail, index) => {
        let node = null;

        // plan_curve_mh less than equal to 8
        if (detail.plan_curve_mh <= 8) {
          node = (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(11, 1fr)",
                gridTemplateRows: "repeat(11, 1fr)",
                gap: 1,
                backgroundColor: color[detail.weekStatus],
                borderRadius: "5px",
              }}
            ></div>
          );
        }
        // plan_curve_mh greater than 8 and less than equal to 10
        else if (detail.plan_curve_mh > 8 && detail.plan_curve_mh <= 10) {
          node = <Matrix11by11 key={index} />;
        }

        // plan_curve_mh greater than 10 and
        else if (detail.plan_curve_mh > 10) {
          node = <Matrix16by16 key={index} />;
        }

        return (
          <React.Fragment key={detail.id}>
            <span
              style={{
                display: "block",
                position: "absolute",
                bottom: "2px",
                left: "4px",
                backgroundColor: "white",
                padding: "1px 2px",
                textTransform: "capitalize",
              }}
            >
              {task.details?.length === 1
                ? String(task.title)
                    .split("_")
                    .join(" ")
                    .substring(0, 3)
                    .concat("...")
                : task.details?.length === 2
                ? String(task.title)
                    .split("_")
                    .join(" ")
                    .substring(0, 8)
                    .concat("...")
                : task.details?.length === 3
                ? String(task.title)
                    .split("_")
                    .join(" ")
                    .substring(0, 15)
                    .concat("...")
                : task.details?.length === 4
                ? String(task.title).split("_").join(" ").substring(0, 20)
                : String(task.title).split("_").join(" ")}
            </span>
            {node}
          </React.Fragment>
        );
      })
    : null;
}
