import { useCallback, useEffect, useState, createRef, useRef } from "react";
import {
  Form,
  Layout,
  Button,
  Typography,
  Row,
  Col,
  Space,
  message,
  Select,
  Modal,
  Input,
} from "antd";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import AppPageHeader from "../../../common/PageHeader";
import InventoryLayOutCommon from "../../InventoryFabric/LayOut/InventoryLayOutCommon";
import OBForm from "../OBForm";
import OBTable from "../OBTable";
import OBFormView from "../OBShowForm/viewOB";
import {
  OB_SAM_DATA_BANK_LIST,
  OB_ADD,
  OB_LIST,
  OB_EDIT,
  OB_EXCEL_REPORT,
  OB_TEMPLATES,
  OB_PDF_REPORT,
  BUYER_INQUIRY_LIST_ENDPOINT,
  UPDATE_MANPOWER,
  GET_OB_INQUIRY_LIST,
  GET_OB_STYLE_LIST,
  OB_STYLE,
  SUBSTYLE_LIST
} from "../../../apiServices/API_ENDPOINTS";
import { getData, postData, putData } from "../../../apiServices/common";
import HandleNotification from "../../../common/Notification";
import { hasPermission, isArrayAndHasValue } from "../../../utils/functions";
import TargetTable from "../TargetTable";
import MachineSummary from "../MachineSummary";
import ExpectedBreakdown from "../ExpectedProductionBreakdown";
import { DownloadOutlined } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import { flushSync } from "react-dom";
import { OB_REPORT_PERMISSION, PACKING_CREATE_PERMISSION } from "routes/permissions";
const OBEntry = (props) => {
  // Props
  const { edit, view, manpowerMode } = props;

  // States
  const [obSamBanks, setObSamBanks] = useState(null);
  const [sections, setSections] = useState([]);
  const [obShowData, setObShowData] = useState(null);
  const [obTemplates, setObTemplates] = useState(null);
  const [buyerInquiryList, setBuyerInquiryList] = useState(null);
  const [expectedNoOfWorkers, setExpectedNoOfWorkers] = useState(null);
  const [obInquiryList, setObInquiryList] = useState(null);
  const [obStyleList, setObStyleList] = useState(null);
  const [CmStatus, setCmStatus] = useState(null);
  const [styleList, setStyleList] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalExcel, setIsModalExcel] = useState(false);
  const [targetHour, setTargetHour] = useState("");
  const [subStyleList, setSubStyleList] = useState(null);
  const [isPrint, setIsPrint] = useState(false);

  // Antd
  const [form] = Form.useForm();
  const [obShowForm] = Form.useForm();
  const { Content } = Layout;
  const { Title } = Typography;
  const { Option } = Select;

  // Router
  const { ob_id } = useParams();
  const navigate = useNavigate();

  // Others
  const exportRef = createRef(null);

  const printFn = useReactToPrint({
    contentRef: exportRef,
    documentTitle: "Operation Bulletin",
    onAfterPrint: () => {
      setIsPrint(false)
    }


  })


  const getStyles = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      page: filterValues?.page || 1,
      ...filterValues,
    };
    const query = `${OB_STYLE}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setStyleList(response?.data?.data?.data);
    }
  };

  const getSubStyles = async (filterValues) => {
    const bodyData = {
      per_page: filterValues?.per_page || 10,
      page: filterValues?.page || 1,
      ...filterValues,
    };
    const query = `${SUBSTYLE_LIST}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setSubStyleList(response?.data?.data?.data);
    }
  };

  const getAllSections = async (filterValues) => {
    const query = `${OB_SAM_DATA_BANK_LIST}`;
    const bodyData = {
      per_page: filterValues?.per_page || 20,
      page: filterValues?.page || 1,
      ...filterValues,
    };
    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      const allOBSAMBanks = response?.data?.data?.data;
      isArrayAndHasValue(allOBSAMBanks) && setObSamBanks(allOBSAMBanks);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting all sections data!",
        null,
      );
    }
  };

  const setOBTableData = async (id) => {
    const query = `${OB_LIST}/${id}/edit`;
    const response = await getData(query);

    if (response && response?.data?.code === 200) {
      const allSections = [...sections];
      const obData = response?.data?.data;
      setObShowData(obData);
      setExpectedNoOfWorkers(obData?.summary?.ob_summary?.no_of_workers);
      setCmStatus(obData?.inquiry_details?.cm_status);

      // Iterate and create sections array
      obData?.operation_bulletine_details?.forEach((obDetail) => {
        // const foundSAM = obDetail?.sam_data_bank;
        const foundSAM = {
          ...obDetail?.sam_data_bank,
          updated_sam: obDetail?.updated_sam,
          manual_worker_helper_sam:
            obDetail?.sam_data_bank?.manual_worker_helper_sam ||
            obDetail?.sam_data_bank?.worker_helper_sam ||
            null,
          manual_worker_iron_sam:
            obDetail?.sam_data_bank?.manual_worker_iron_sam ||
            obDetail?.sam_data_bank?.worker_iron_sam ||
            null,
          manual_worker_machine_sam:
            obDetail?.sam_data_bank?.manual_worker_machine_sam ||
            obDetail?.sam_data_bank?.worker_machine_sam ||
            null,
          color: obDetail?.color,
        };

        const foundSectionId = foundSAM ? foundSAM?.ob_section_info?.id : null;
        const foundSection = allSections?.find(
          (section) => section?.section_id === foundSectionId,
        );

        if (!foundSection) {
          // Push new data to array
          allSections.push({
            section_id: foundSAM?.ob_section_info?.id,
            section_name: foundSAM?.ob_section_info?.name,
            section_data: [foundSAM],
          });
        } else {
          // Push data to existing array
          const sectionIndex = allSections.findIndex(
            (section) => section?.section_id === foundSAM?.ob_section_info?.id,
          );
          const isExists = allSections[sectionIndex].section_data.some(
            (sam) => sam?.id === foundSAM?.id,
          );

          if (!isExists || manpowerMode) {
            allSections[sectionIndex].section_data.push(foundSAM);
          }
        }
      });

      // Set sections state
      isArrayAndHasValue(allSections) && setSections(allSections);
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting selected ob info!",
        null,
      );
    }
  };

  const getSelectedObInfo = useCallback(
    async (id, no_of_hour, per_hour_target, manpowerMode) => {
      const editQuery = `${OB_LIST}/${id}/edit`;
      let showQuery = `${OB_LIST}/details-data/${id}`;
      
      if (manpowerMode) showQuery = `${OB_LIST}/details-data/${id}?manpowerMode=1`;
      const query = (view || manpowerMode) ? showQuery : editQuery;

      const response = await getData(query);

      if (response && response?.data?.code === 200) {
        const allSections = [...sections];
        const obData = response?.data?.data;

        setObShowData(obData);
        setExpectedNoOfWorkers(obData?.summary?.ob_summary?.no_of_workers);
        setCmStatus(obData?.inquiry_details?.cm_status);

        const inquiryDetailsData = obData?.inquiry_details;
        const styleData = obData?.style;
        const buyerInquiryListCopy = isArrayAndHasValue(buyerInquiryList) ? [...buyerInquiryList] : [];
        const styleListCopy = isArrayAndHasValue(styleList) ? [...styleList] : [];
        const subStyleListCopy = isArrayAndHasValue(subStyleList) ? [...subStyleList] : [];
        const isExistsInquiry = buyerInquiryListCopy.some((item) => item?.id === inquiryDetailsData?.id);
        const isExistsStyle = styleListCopy.some((item) => item?.id === styleData?.id);
        const isExistsSubStyle = subStyleListCopy.some((item) => item?.id === obData?.sub_style_info?.id);

        if (!isExistsInquiry) {
          buyerInquiryListCopy.push(inquiryDetailsData);
          setBuyerInquiryList(buyerInquiryListCopy);
        }

        if (!isExistsStyle) {
          styleListCopy.push(styleData);
          setStyleList(styleListCopy);
        }

        if(!isExistsSubStyle) {
          subStyleListCopy.push(obData?.sub_style_info);
          setSubStyleList(subStyleListCopy);
        }

        // Set form fields data
        form.setFieldsValue({
          style_id: obData?.style?.id,
          buying_office_id: obData?.buying_office?.id,
          buying_office_name: obData?.buying_office?.name,
          buyer_id: obData?.buyer?.id,
          buyer_name: obData?.buyer?.name,
          sales_contract_id: obData?.sales_contract?.id,
          sales_contract_no: obData?.sales_contract?.reference_no,
          entry_date: obData?.entry_date ? moment(obData?.entry_date) : null,
          description: obData?.style?.description,
          inquiry_id: obData?.inquiry_id,
          note: obData?.note,
          sub_style_id: obData?.sub_style_info?.id,
          sub_contract_factory_id: obData?.sub_contract_factory_id,
          factory_name: obData?.factory_info?.name,
          subcontract_status: obData?.subcontract_status ||  obData?.sub_contract_factory_id,
        });

        // Iterate and create sections array
        obData?.operation_bulletine_details?.forEach((obDetail) => {
          // const foundSAM = obDetail?.sam_data_bank;
          const foundSAM = {
            ...obDetail?.sam_data_bank,
            updated_sam: obDetail?.updated_sam,
            manual_worker_helper_sam: obDetail?.sam_data_bank?.manual_worker_helper_sam || (obDetail?.sam_data_bank?.worker_helper_sam || null),
            manual_worker_iron_sam: obDetail?.sam_data_bank?.manual_worker_iron_sam || (obDetail?.sam_data_bank?.worker_iron_sam || null),
            manual_worker_machine_sam: obDetail?.sam_data_bank?.manual_worker_machine_sam || (obDetail?.sam_data_bank?.worker_machine_sam || null),
            color: obDetail?.color,
            ob_detail_id: obDetail?.id,
          }

          const foundSectionId = foundSAM
            ? foundSAM?.ob_section_info?.id
            : null;
          const foundSection = allSections?.find(
            (section) => section?.section_id === foundSectionId,
          );


          if (!foundSection) {
            // Push new data to array
            allSections.push({
              section_id: foundSAM?.ob_section_info?.id,
              section_name: foundSAM?.ob_section_info?.name,
              section_data: [foundSAM],
            });
          } else {
            // Push data to existing array
            const sectionIndex = allSections.findIndex(
              (section) =>
                section?.section_id === foundSAM?.ob_section_info?.id,
            );
            const isExists = allSections[sectionIndex].section_data.some(
              (sam) => sam?.id === foundSAM?.id,
            );

            if (!isExists || manpowerMode) {
              allSections[sectionIndex].section_data.push(foundSAM);
            }
          }
        });

        // Set sections state
        isArrayAndHasValue(allSections) && setSections(allSections);
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error getting selected ob info!",
          null,
        );
      }
    },
    [form, sections, view],
  );

  const getAllObTemplates = async (filterValues) => {
    const { selectedTemplate, filterValue } = filterValues || {};
    let filterName = null;
    let endpoint = `${OB_TEMPLATES}`;

    switch (selectedTemplate) {
      case "ob_template":
        filterName = "tamplate_name";
        endpoint = `${OB_TEMPLATES}`;
        break;
      case "inquiry":
        filterName = "inquiry_no";
        endpoint = `${GET_OB_INQUIRY_LIST}`;
        break;
      case "style":
        filterName = "style_no";
        endpoint = `${GET_OB_STYLE_LIST}`;
        break;
      default:
        break;
    }

    const query = endpoint;
    const bodyData = {
      ...(filterName ? { [filterName]: filterValue } : {}),
    };

    const response = await getData(query, false, bodyData);
    if (response && response?.data?.code === 200) {
      const masterData = response?.data?.data?.data;
      const templateSamData =
        isArrayAndHasValue(masterData) &&
        masterData?.map((item) => {
          const samData =
            isArrayAndHasValue(item?.ob_tamplet_details) &&
            item?.ob_tamplet_details?.map((sam) => {
              return {
                ...sam?.sam_data_bank,
              };
            });
          return {
            sam_data_bank: samData,
            template_name: item?.tamplate_name,
            id: item?.id,
          };
        });
      templateSamData && setObTemplates(templateSamData);
    } else {
      message.error("Error getting OB Templates");
    }
  };

  const getAllBuyerInquiry = async (filterValues) => {
    const bodyData = {
      per_page: 10,
      ...filterValues,
    };
    const query = `${BUYER_INQUIRY_LIST_ENDPOINT}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setBuyerInquiryList(response?.data?.data?.data);
    }
  };

  const getAllOBInquiry = async (filterValues) => {
    const bodyData = {
      per_page: 20,
      ...filterValues,
    };
    const query = `${GET_OB_INQUIRY_LIST}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setObInquiryList(response?.data?.data?.data);
    }
  };

  const getAllOBStyle = async (filterValues) => {
    const bodyData = {
      per_page: 20,
      ...filterValues,
    };
    const query = `${GET_OB_STYLE_LIST}`;

    const response = await getData(query, false, bodyData);

    if (response && response?.data?.code === 200) {
      setObStyleList(response?.data?.data?.data);
    }
  };

  useEffect(() => {
    // Get all sections data on first load
    getAllSections();
    getAllObTemplates();
    getAllBuyerInquiry();
    getAllOBInquiry();
    getAllOBStyle();
    getStyles();
    getSubStyles();
  }, []);

  useEffect(() => {
    // Get selected ob info for edit
    if((ob_id || ob_id === 0) && manpowerMode) {
      getSelectedObInfo(ob_id, null, null, true);
    }
    if ((ob_id || ob_id === 0) && !manpowerMode) {
      getSelectedObInfo(ob_id);
    }
  }, [ob_id, view, manpowerMode]);

  const onSelectSAM = (id) => {
    const allSections = [...sections];
    const foundSAM = obSamBanks?.find((sam) => sam?.id === id);
    const foundSectionId = foundSAM ? foundSAM?.ob_section_info?.id : null;
    const foundSection = sections?.find(
      (section) => section?.section_id === foundSectionId,
    );

    if (!foundSectionId) {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting section!",
        null,
      );
      return;
    }

    if (!foundSection) {
      // Push new data to array
      allSections.push({
        section_id: foundSAM?.ob_section_info?.id,
        section_name: foundSAM?.ob_section_info?.name,
        section_data: [foundSAM],
      });
    } else {
      // Push data to existing array
      const sectionIndex = allSections.findIndex(
        (section) => section?.section_id === foundSAM?.ob_section_info?.id,
      );
      const isExists = allSections[sectionIndex].section_data.some(
        (sam) => sam?.id === foundSAM?.id,
      );

      if (!isExists) {
        allSections[sectionIndex].section_data.push(foundSAM);
      } else {
        // If data section already exists
        HandleNotification(
          "error",
          "bottomRight",
          "Section already selected before!",
          null,
        );
      }
    }

    form.resetFields(["section_id"]);
    isArrayAndHasValue(allSections) && setSections(allSections);
  };

  const removeRow = (id) => {
    // Find existing section data by id in sections array
    // remove if exists
    const allSections = [...sections];
    const filteredSections = allSections.map((section) => {
      const filteredSectionData = section?.section_data.filter(
        (sam) => sam?.id !== id,
      );
      return {
        ...section,
        section_data: filteredSectionData,
      };
    });

    setSections(filteredSections);
  };

  const rowPositionChange = (id, sectionId, destination) => {
    const allSections = structuredClone(sections);
    const selectedSection = allSections.find((sectionItem) => sectionItem?.section_id === sectionId);
    const sectionData = selectedSection?.section_data;

    const activeIndex = sectionData.findIndex((item) => item?.id === id);
    const overIndex = destination === "up" ? activeIndex - 1 : activeIndex + 1;

    // Ensure the indices are within bounds
    if (
      activeIndex < 0 ||
      activeIndex >= sectionData.length ||
      overIndex < 0 ||
      overIndex >= sectionData.length
    ) {
      return;
    }

    // Remove the item from the array
    const [draggedItem] = sectionData.splice(activeIndex, 1);

    // Insert the dragged item at the new position
    sectionData.splice(overIndex, 0, draggedItem);

    // Update the position key in every item
    allSections.forEach((item, index) => {
      item.position = index + 1;
    });

    // Now, dataSource is updated with the new order
    setSections(allSections);
  };

  const onChangeValue = (value, id, name) => {
    // Create a lookup object for quick access to sections and items
    const sectionLookup = {};
    const itemLookup = {};

    sections.forEach((section) => {
      sectionLookup[section.section_id] = section;
      section.section_data.forEach((item) => {
        itemLookup[item.id] = item;
      });
    });

    // Check if the section and item exist
    if (itemLookup[id] && sectionLookup[itemLookup[id].section_id]) {
      // Update the specified field (name parameter) with the new value
      itemLookup[id][name] = value ? value : 0;

      // Update the state with the modified sections array
      setSections([...sections]); // This spread operator is crucial for ensuring state update
    }
  };

  const onChangeSAMValue = (value, id, name) => {
    // Create a lookup object for quick access to sections and items
    const sectionLookup = {};
    const itemLookup = {};

    sections.forEach((section) => {
      sectionLookup[section.section_id] = section;
      section.section_data.forEach((item) => {
        itemLookup[item.id] = item;
      });
    });

    // Check if the section and item exist
    if (itemLookup[id] && sectionLookup[itemLookup[id].section_id]) {
      // Update the specified field (name parameter) with the new value
      itemLookup[id][name] = value ? value : 0;
      itemLookup[id]['updated_sam'] = value ? value : 0;

      // Update the state with the modified sections array
      setSections([...sections]); // This spread operator is crucial for ensuring state update
    }
  };

  const showModal = (select) => {
    if (select === "excel") {
      setIsModalExcel(true)
    } else {
      setIsModalExcel(false)
    }
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (!targetHour) {
      message.error("Target hour cannot be empty!");
      return;
    }
    try {
      if (isModalExcel) {
        onDownloadExcel(`?per_hour_target=${targetHour}&button=ok`)
      } else {
        onDownloadPDF(`?per_hour_target=${targetHour}&button=ok`)
      }
    } catch (error) {
      HandleNotification('error', 'bottomRight', 'Something Went Wrong!', null) 
    }
    setIsModalVisible(false);
  };
  const handleSkip = () => {
    try {
      if (isModalExcel) {
        onDownloadExcel(`?button=skip`)
      } else {
        onDownloadPDF(`?button=skip`)
      }
    } catch (error) {
      HandleNotification('error', 'bottomRight', 'Something Went Wrong!', null) 
    }

    setIsModalVisible(false);

  };

  const onDownloadExcel = async (queryParams) => {

    const no_of_hour = obShowForm.getFieldValue("no_of_hour");
    const per_hour_target = obShowForm.getFieldValue("per_hour_target");
    const bodyData = {
      id: ob_id,
      no_of_hour: no_of_hour ? Number(no_of_hour) : null,
      per_hour_target: per_hour_target ? Number(per_hour_target) : null,
    };
    const query = `${OB_EXCEL_REPORT}${queryParams}`;

    const response = await getData(
      query,
      false,
      bodyData,
      { "Content-Type": "blob" }, //Custome Header
      "arraybuffer", //Response-Type to get excel file
    );

    if (response && response?.status === 200) {
      const outputFilename = `ob.xlsx`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting excel report!",
        null,
      );
    }
  };
  const onDownloadPDF = async (queryParams) => {
    // Replace obShowForm and ob_id with appropriate values
    const no_of_hour = obShowForm.getFieldValue("no_of_hour");
    const per_hour_target = obShowForm.getFieldValue("per_hour_target");
    const bodyData = {
      id: ob_id, // Replace ob_id with the appropriate value
      no_of_hour: no_of_hour ? Number(no_of_hour) : null,
      per_hour_target: per_hour_target ? Number(per_hour_target) : null,
    };
    const query = `${OB_PDF_REPORT}${ob_id}${queryParams}`;
    try {
      const response = await getData(
        query,
        false,
        bodyData,
        { "Content-Type": "application/pdf" }, // Change Content-Type to application/pdf
        "blob", // Change Response-Type to blob
      );
      if (response?.status === 200) {
        const outputFilename = `${Date()}ob.pdf`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(
          new Blob([response.data], {
            type: "application/pdf",
          }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
      } else {
        HandleNotification(
          "error",
          "bottomRight",
          "Error getting PDF report!",
          null,
        );
      }
    } catch (error) {
      // Handle any exceptions here
      HandleNotification(
        "error",
        "bottomRight",
        "Error getting PDF report!",
        null,
      );
    }
  };


  useEffect(() => {
    if (isPrint) {
      printFn()
      setIsPrint(false)
    }
  }, [isPrint])

  
  const extra = (
    <div className="extra-button-container">
      {!view && (
        <Button
          htmlType="submit"
          type="primary"
          size="small"
          style={{ marginRight: 5 }}
          onClick={() => {
            form.submit();
          }}
          disabled={CmStatus === 1}
        >
          {" "}
          {`Save`}
        </Button>
      )}

      {!view && (
        <Button
          htmlType="submit"
          size="small"
          onClick={() => form.resetFields()}
        >
          {" "}
          Reset
        </Button>
      )}

      {view && (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setIsPrint(true);
            }}
            disabled={!hasPermission([OB_REPORT_PERMISSION])}
          >
            {hasPermission([PACKING_CREATE_PERMISSION])
              ? "Print"
              : "No Permission"}
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => showModal("excel")}
            disabled={!hasPermission([OB_REPORT_PERMISSION])}
          >
            {hasPermission([PACKING_CREATE_PERMISSION])
              ? "Download Excel"
              : "No Permission"}
          </Button>
          <Button
            type="primary"
            size="small"
            onClick={() => showModal("pdf")}
            disabled={!hasPermission([OB_REPORT_PERMISSION])}
          >
            {hasPermission([PACKING_CREATE_PERMISSION])
              ? "Download PDF"
              : "No Permission"}
          </Button>
        </Space>
      )}
    </div>
  );

  const onFinish = async (formValues) => {
    // Sam Data Calculate
    const samData = {
      total_smv_machine_sam: 0,
      total_smv_helper_sam: 0,
      total_smv_iron_sam: 0,
      total_target_machine_sam: 0,
      total_target_helper_sam: 0,
      total_target_iron_sam: 0,
      total_sam: 0,
      total_updated_sam: 0,
      total_updated_smv_helper_sam: 0,
      total_updated_smv_iron_sam: 0,
      total_updated_smv_machine_sam: 0,
    };

    sections?.forEach((section) => {
      section?.section_data?.forEach((sectionDataItem) => {
        samData.total_sam += sectionDataItem?.sam;
        samData.total_updated_sam += (sectionDataItem?.updated_sam ? Number(sectionDataItem?.updated_sam) : sectionDataItem?.sam);

        switch (sectionDataItem?.ob_machine_type_info?.name.toLowerCase()) {
          case "helper":
            samData.total_smv_helper_sam += sectionDataItem?.sam;
            samData.total_updated_smv_helper_sam += (sectionDataItem?.updated_sam ? Number(sectionDataItem?.updated_sam) : sectionDataItem?.sam);
            samData.total_target_helper_sam += 60 / (sectionDataItem?.updated_sam ? Number(sectionDataItem?.updated_sam) : sectionDataItem?.sam);
            break;
          case "hp":
            samData.total_smv_helper_sam += sectionDataItem?.sam;
            samData.total_updated_smv_helper_sam += (sectionDataItem?.updated_sam ? Number(sectionDataItem?.updated_sam) : sectionDataItem?.sam);
            samData.total_target_helper_sam += 60 / (sectionDataItem?.updated_sam ? Number(sectionDataItem?.updated_sam) : sectionDataItem?.sam);
            break;
          case "iron":
            samData.total_smv_iron_sam += sectionDataItem?.sam;
            samData.total_updated_smv_iron_sam += (sectionDataItem?.updated_sam ? Number(sectionDataItem?.updated_sam) : sectionDataItem?.sam);
            samData.total_target_iron_sam += 60 / (sectionDataItem?.updated_sam ? Number(sectionDataItem?.updated_sam) : sectionDataItem?.sam);
            break;
          case "ir":
            samData.total_smv_iron_sam += sectionDataItem?.sam;
            samData.total_updated_smv_iron_sam += (sectionDataItem?.updated_sam ? Number(sectionDataItem?.updated_sam) : sectionDataItem?.sam);
            samData.total_target_iron_sam += 60 / (sectionDataItem?.updated_sam ? Number(sectionDataItem?.updated_sam) : sectionDataItem?.sam);
            break;
          default:
            samData.total_smv_machine_sam += sectionDataItem?.sam;
            samData.total_updated_smv_machine_sam += (sectionDataItem?.updated_sam ? Number(sectionDataItem?.updated_sam) : sectionDataItem?.sam);
            samData.total_target_machine_sam += 60 / (sectionDataItem?.updated_sam ? Number(sectionDataItem?.updated_sam) : sectionDataItem?.sam);
            break;
        }
      });
    });

    // Section Values
    const sectionValues = sections?.flatMap((section) => {
      return section?.section_data?.flatMap((sectionDataItem) => {
        return {
          ob_detail_id: sectionDataItem?.ob_detail_id || null,
          sam_data_bank_id: sectionDataItem?.id,
          updated_sam: Number(sectionDataItem?.updated_sam) ?? sectionDataItem?.sam,
          target_helper_sam:
            sectionDataItem?.ob_machine_type_info?.name.toLowerCase() ===
              "helper" ||
              sectionDataItem?.ob_machine_type_info?.name.toLowerCase() === "hp"
              ? Number((60 / sectionDataItem?.sam).toFixed(2))
              : null,
          target_iron_sam:
            sectionDataItem?.ob_machine_type_info?.name.toLowerCase() ===
              "iron" ||
              sectionDataItem?.ob_machine_type_info?.name.toLowerCase() === "ir"
              ? Number((60 / sectionDataItem?.sam).toFixed(2))
              : null,
          target_machine_sam:
            sectionDataItem?.ob_machine_type_info?.name.toLowerCase() !==
              "helper" &&
              sectionDataItem?.ob_machine_type_info?.name.toLowerCase() !==
              "hp" &&
              sectionDataItem?.ob_machine_type_info?.name.toLowerCase() !==
              "ir" &&
              sectionDataItem?.ob_machine_type_info?.name.toLowerCase() !== "iron"
              ? Number((60 / sectionDataItem?.sam).toFixed(2))
              : null,
          manual_worker_machine_sam: sectionDataItem?.manual_worker_machine_sam ?? null,
          manual_worker_helper_sam: sectionDataItem?.manual_worker_helper_sam ?? null,
          manual_worker_iron_sam: sectionDataItem?.manual_worker_iron_sam ?? null,
          color: sectionDataItem?.color || null,
        };
      });
    });

    const bodyData = {
      style_id: formValues?.style_id ? Number(formValues?.style_id) : null,
      buying_office_id: formValues?.buying_office_id
        ? Number(formValues?.buying_office_id)
        : null,
      buyer_id: formValues?.buyer_id ? Number(formValues?.buyer_id) : null,
      sales_contract_id: formValues?.sales_contract_id
        ? Number(formValues?.sales_contract_id)
        : null,
      entry_date: formValues?.entry_date
        ? moment(formValues?.entry_date).format("YYYY-MM-DD")
        : null,
      total_updated_smv_machine_sam: Number(samData.total_updated_smv_machine_sam).toFixed(2),
      total_updated_smv_helper_sam: Number(samData.total_updated_smv_helper_sam).toFixed(2),
      total_updated_smv_iron_sam: Number(samData.total_updated_smv_iron_sam).toFixed(2),
      total_smv_machine_sam: Number(samData.total_smv_machine_sam).toFixed(2),
      total_smv_helper_sam: Number(samData.total_smv_helper_sam).toFixed(2),
      total_smv_iron_sam: Number(samData.total_smv_iron_sam).toFixed(2),
      total_target_machine_sam: Number(
        samData.total_target_machine_sam.toFixed(2),
      ),
      total_target_helper_sam: Number(
        samData.total_target_helper_sam.toFixed(2),
      ),
      total_target_iron_sam: Number(samData.total_target_iron_sam.toFixed(2)),
      total_sam: samData.total_sam,
      total_updated_sam: samData.total_updated_sam,
      values: sectionValues,
      inquiry_id: formValues?.inquiry_id || null,
      expectedNoOfWorkers: expectedNoOfWorkers,
      note: formValues?.note || null,
      sub_style_id: formValues?.sub_style_id || null,
      sub_contract_factory_id: formValues?.sub_contract_factory_id || null,
      subcontract_status: formValues?.subcontract_status ? 1 : 0,
    };

    // API Call
    let query;
    if (manpowerMode) {
      query = `${UPDATE_MANPOWER}/${ob_id}`;
    } else {
      query = ob_id || ob_id === 0 ? `${OB_EDIT}/${ob_id}` : `${OB_ADD}`;

    }
    let response = null;

    if (ob_id || ob_id === 0) {
      response = await putData(query, bodyData);
    } else {
      response = await postData(query, bodyData);
    }

    if (response && response?.code === 200) {
      HandleNotification(
        "success",
        "bottomRight",
        "Operation bulletin added successfully!",
        null,
      );
      // Navigate to ob list page
      if (!edit && !manpowerMode) {
        window.open(`/merchandising/operation-bulletin/update-manpower/${response?.data?.id}`);
      } else {
        navigate(`/merchandising/operation-bulletin`);
      }
    } else {
      HandleNotification(
        "error",
        "bottomRight",
        "Error adding operation bulletin!",
        null,
      );
    }
  };

  const createDataForOBTemplate = (id) => {
    const foundOb = obTemplates?.find((ob) => ob?.id === id);
    const sam_data_bank = foundOb?.sam_data_bank;
    const allSections = [];

    isArrayAndHasValue(sam_data_bank) &&
      sam_data_bank?.forEach((item) => {
        const sectionExists = allSections?.find(
          (section) => section?.section_id === item?.ob_section_info?.id,
        );

        if (sectionExists) {
          sectionExists?.section_data?.push(item);
        } else {
          allSections.push({
            section_id: item?.ob_section_info?.id,
            section_name: item?.ob_section_info?.name,
            section_data: [item],
          });
        }
      });

    isArrayAndHasValue(allSections) && setSections(allSections);
  };

  const onSelectObTemplate = (id) => {
    const from_value = form.getFieldValue("from_template_name");

    switch (from_value) {
      case "ob_template":
        createDataForOBTemplate(id);
        break;
      case "inquiry":
        setOBTableData(id);
        break;
      case "style":
        setOBTableData(id);
        break;
      default:
        break;
    }
  };

  const onSortTableBody = useCallback(
    ({ oldIndex, newIndex, collection }) => {
      const sectionsCopy = [...sections];
      const selectedSectionData = sectionsCopy?.find(
        (section) => section?.section_id === collection,
      )?.section_data;

      // Remove the item from the array
      const [draggedItem] = selectedSectionData.splice(oldIndex, 1);

      // Insert the dragged item at the new position
      selectedSectionData.splice(newIndex, 0, draggedItem);

      // Update the position key in every item
      selectedSectionData.forEach((item, index) => {
        item.position = index + 1;
      });

      // Set modified array to the state
      setSections(sectionsCopy);
    },
    [sections],
  );

  const onSortMainTable = useCallback(
    ({ oldIndex, newIndex, collection }) => {
      const sectionsCopy = [...sections];

      // Remove the item from the array
      const [draggedSection] = sectionsCopy.splice(oldIndex, 1);

      // Insert the dragged item at the new position
      sectionsCopy.splice(newIndex, 0, draggedSection);

      // Update the position key in every item
      sectionsCopy.forEach((item, index) => {
        item.position = index + 1;
      });

      // Set modified array to the state
      setSections(sectionsCopy);
    },
    [sections],
  );

  const clearSections = () => {
    setSections([]);
  }



  return (
    <InventoryLayOutCommon>
      <AppPageHeader
        extra={extra}
        title={
          <Title level={5}>
            {`Operation Bulletin ${view ? "Show" : ob_id && !view ? "Edit" : "Entry"
              }`}
          </Title>
        }
      />
      <Content className="item-details">
        <div className="printCustomDesign" ref={exportRef}>
          {!view && (
            <Form layout="vertical" onFinish={onFinish} form={form}>
              <OBForm
                form={form}
                obSamBanks={obSamBanks}
                getAllSections={getAllSections}
                onSelectSAM={onSelectSAM}
                view={view}
                edit={edit}
                manpowerMode={manpowerMode}
                obTemplates={obTemplates}
                getAllObTemplates={getAllObTemplates}
                onSelectObTemplate={onSelectObTemplate}
                buyerInquiryList={buyerInquiryList}
                getAllBuyerInquiry={getAllBuyerInquiry}
                obInquiryList={obInquiryList}
                obStyleList={obStyleList}
                styleList={styleList}
                getStyles={getStyles}
                setStyleList={setStyleList}
                getSubStyles={getSubStyles}
                subStyleList={subStyleList}
                setSubStyleList={setSubStyleList}
                clearSections={clearSections}
              />
            </Form>
          )}
          {/* only for show ob */}
          {view && obShowData && <OBFormView obData={obShowData} view={view} edit={edit} />}

          <Row gutter={24}>
            <Col span={view || manpowerMode || obShowData ? 20 : 24}>
              <>
                {!view && (
                  <Form.Item
                    label="Insert Operations"
                    name="section_id"
                    className="p-2"
                    style={{
                      position: "sticky",
                      top: 0,
                      zIndex: 3,
                      background: "#FAFAFA",
                    }}
                  >
                    <Select
                      onSelect={(value) => onSelectSAM(value)}
                      onSearch={(value) =>
                        getAllSections({ combination: value })
                      }
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Search"
                      optionFilterProp="children"
                      size="small"
                      disabled={manpowerMode}
                    >
                      {obSamBanks?.map((item, index) => (
                        <Option
                          key={item?.id}
                          value={item?.id}
                          label={item?.combination}
                        >
                          {item?.combination}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                {isArrayAndHasValue(sections) && (
                  <OBTable
                    obData={obShowData}
                    sectionData={sections}
                    removeRow={removeRow}
                    onChangeValue={onChangeValue}
                    onChangeSAMValue={onChangeSAMValue}
                    view={view}
                    edit={edit}
                    manpowerMode={manpowerMode}
                    rowPositionChange={rowPositionChange}
                    onSortTableBody={onSortTableBody}
                    onSortMainTable={onSortMainTable}
                  />
                )}
              </>
            </Col>
          </Row>
          {
            !isPrint && (
              <Row gutter={24}>
              <Col span={24}>
                {view && obShowData && (
                  <ExpectedBreakdown breakdownData={obShowData?.summary} />
                )}
              </Col>
            </Row>  
            )
          }
          
          <Row gutter={24}>
            {view && obShowData && (
              <>
                <Col span={12}>
                  <MachineSummary
                    machineSummary={obShowData?.machine_summary}
                  />
                </Col>
                <Col span={12}>
                  <TargetTable
                    obShowForm={obShowForm}
                    obShowData={obShowData}
                    manpowerMode={manpowerMode}
                  />
                </Col>
              </>
            )}
          </Row>
        </div>
      </Content>
      <Modal
        title="Increase Per Hour Target"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button
            key="link"
            type="default"
            icon={<DownloadOutlined />}
            onClick={handleSkip}
          >
            Skip
          </Button>,

          <Button key="submit" type="primary"
            icon={<DownloadOutlined />}

            onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        <Input
          placeholder="Enter Per hour target"
          value={targetHour}
          onChange={(e) => setTargetHour(e.target.value)}
        />
      </Modal>
    </InventoryLayOutCommon>
  );
};

export default OBEntry;
