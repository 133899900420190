import { Line } from "@ant-design/charts";
import { Card, Table } from "antd";
import React from "react";

export default function ToDay() {
  const data = [
    { line: "Line # 01", profit: 10 },
    { line: "Line # 08", profit: -60 },
    { line: "Line # 03", profit: 30 },
    { line: "Line # 04", profit: 40 },
    { line: "Line # 05", profit: 50 },
    { line: "Line # 06", profit: 60 },
    { line: "Line # 07", profit: 0 },
    { line: "Line # 09", profit: -50 },
    { line: "Line # 02", profit: 20 },
    { line: "Line # 10", profit: -40 },
    { line: "Line # 11", profit: -30 },
    { line: "Line # 12", profit: -20 },
  ];

  const config = {
    data,
    xField: "line",
    yField: "profit",
    point: {
      size: 6,
      shape: "circle",
      style: {
        fill: "magenta",
        stroke: "#00bfff",
        lineWidth: 2,
      },
    },
    lineStyle: {
      stroke: "#00bfff",
      lineWidth: 2,
    },
    smooth: true,
    xAxis: {
      label: {
        autoRotate: false,
        style: { fill: "#fff" },
      },
      title: {
        text: "Lines",
        style: { fill: "#fff" },
      },
    },
    yAxis: {
      label: {
        style: { fill: "#fff" },
      },
      title: {
        text: "Profit (Taka)",
        style: { fill: "#fff" },
      },
    },
    tooltip: {
      formatter: (datum) => ({
        name: "Profit",
        value: `${datum.profit} Taka`,
      }),
    },
    background: {
      fill: "#333",
    },
    theme: {
      backgroundColor: "#333",
    },
  };

  const dataSource = [
    {
      key: "1",
      sewingLine: "Line #01",
      lineChief: "RAFIQUL ISLAM",
      buyer: "CORONA/SHAHI C & A",
      lineAttendance: 77,
      todaysProdPc: 1230,
      avgCMUSD: 1.34,
      incomeCMUSD: 1650,
      incomeCMTaka: 193069,
      expenditureTaka: 202591,
      todaysEstNetProfitTaka: 9523,
      ranking: "6th",
    },
    {
      key: "2",
      sewingLine: "Line #02",
      lineChief: "SHERAJ (ACT)",
      buyer: "G-TEX",
      lineAttendance: 75,
      todaysProdPc: 1670,
      avgCMUSD: 1.8,
      incomeCMUSD: 3010,
      incomeCMTaka: 351702,
      expenditureTaka: 189000,
      todaysEstNetProfitTaka: 162683,
      ranking: "1st",
    },
    // Add the rest of the data here
    {
      key: "17",
      sewingLine: "Line #17",
      lineChief: "AL AMIN",
      buyer: "F & F/GAS",
      lineAttendance: 81,
      todaysProdPc: 880,
      avgCMUSD: 2.19,
      incomeCMUSD: 1931,
      incomeCMTaka: 225890,
      expenditureTaka: 212322,
      todaysEstNetProfitTaka: 13568,
      ranking: "5th",
    },
  ];

  const columns = [
    {
      title: "Sewing Line",
      dataIndex: "sewingLine",
      key: "sewingLine",
    },
    {
      title: "Line Chief",
      dataIndex: "lineChief",
      key: "lineChief",
    },
    {
      title: "Buyer",
      dataIndex: "buyer",
      key: "buyer",
    },
    {
      title: "Line Attendance",
      dataIndex: "lineAttendance",
      key: "lineAttendance",
    },
    {
      title: "Today's Prod/Pc",
      dataIndex: "todaysProdPc",
      key: "todaysProdPc",
    },
    {
      title: "Avg CM/USD",
      dataIndex: "avgCMUSD",
      key: "avgCMUSD",
    },
    {
      title: "Income CM/USD",
      dataIndex: "incomeCMUSD",
      key: "incomeCMUSD",
    },
    {
      title: "Income CM/Taka",
      dataIndex: "incomeCMTaka",
      key: "incomeCMTaka",
    },
    {
      title: "Expenditure Taka",
      dataIndex: "expenditureTaka",
      key: "expenditureTaka",
    },
    {
      title: "Today's Est. Net Profit/Taka",
      dataIndex: "todaysEstNetProfitTaka",
      key: "todaysEstNetProfitTaka",
    },
    {
      title: "Ranking on Profitability",
      dataIndex: "ranking",
      key: "ranking",
    },
  ];
  return (
    <Card
      title="Today's production, income & profit for Line wise:"
      bordered={false}
    >
      <Table dataSource={dataSource} columns={columns} pagination={false} />
      <div
        style={{
          padding: "20px",
          backgroundColor: "#ffffff",
          borderRadius: "10px",
        }}
      >
        <h3
          style={{
            color: "#00bfff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Today's Est. Net Profit/taka
        </h3>
        <Line {...config} />
      </div>
    </Card>
  );
}
