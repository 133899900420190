import { useState } from "react";
import {
  Form,
  Input,
  Select,
  InputNumber,
  Drawer,
  Card,
  Typography,
} from "antd";
import {
  EditOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import "./index.css";
import SizeTracking from "./SizeTracking";
import { commaSeparateNumber } from "utils/functions";

const PackingDetails = (props) => {
  // Props
  const {
    view,
    styleList,
    poDetailsData,
    copyRow,
    removeRow,
    onChangePoDetailsValue,
    calculateQty,
    calculateCBM,
    calculateTotalGrossWeight,
    calculateTotalNetWeight,
    getPackingColorList,
    selectedPoColorList,
    PackReqForm,
    selectedPOUniqueID,
    insertSizeTableData,
    isEdit,
    setPoDetailsData,
  } = props;

  // States
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [hoverData, setHoverData] = useState(null);
  const [styleNo, setStyleNo] = useState('');

  // Antd
  const [packingDetailsForm] = Form.useForm();
  const { Option } = Select;
  const { Title } = Typography;

  const RowInfoUI = ({ rowData }) => {
    return (
      <div
        style={{ padding: "10px", display: rowData ? "unset" : "none" }}
        className="row-ui-bottom"
      >
        <div>
          <strong>Carton Qty:</strong>{" "}
          {rowData?.carton_qty
            ? commaSeparateNumber(rowData?.carton_qty)
            : "N/A"}
          ,<strong>CBM:</strong> {rowData?.cbm || "N/A"},<strong>Item:</strong>{" "}
          {rowData?.item || "N/A"},<strong>Total Gross:</strong>{" "}
          {rowData?.total_gross
            ? commaSeparateNumber(rowData?.total_gross)
            : "N/A"}
          ,<strong>Total Net:</strong>{" "}
          {rowData?.total_net ? commaSeparateNumber(rowData?.total_net) : "N/A"}
          <strong>UPC: </strong> {rowData?.upc || "N/A"}
        </div>
      </div>
    );
  };

  const openDrawer = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <Form form={packingDetailsForm}>
        <div className="">
          <Card
            className="mt-2"
            title={
              <span style={{ fontSize: "14px", fontWeight: 600 }}>
                Style PO Details
              </span>
            }
          >
            <div className="custome-table">
              <table>
                <thead>
                  <tr>
                    <th colSpan={7} className="bc-orange text-center">
                      Carton Details
                    </th>
                    <th colSpan={5} className="bc-orange text-center">
                      Carton Size
                    </th>
                    <th colSpan={2} className="bc-orange text-center">
                      Weight (kg) / Carton
                    </th>
                    <th colSpan={2} className="bc-orange text-center">
                      Total Weight (kg)
                    </th>
                    <th colSpan={1} className="bc-orange text-center">
                      Style
                    </th>
                    <th colSpan={3} className="bc-orange text-center">
                      Actions/Row
                    </th>
                  </tr>
                  <tr>
                    <th
                      key="header_serial_number"
                      className="bc-java text-center"
                    >
                      SL
                    </th>
                    <th key="header_from" className="bc-java text-center">
                      From{" "} <span className="required">*</span>
                    </th>
                    <th key="header_to" className="bc-java text-center">
                      To <span className="required">*</span>
                    </th>
                    <th key="header_qty" className="bc-java text-center">
                      Qty <span className="required">*</span>
                    </th>
                    <th key="header_assortment" className="bc-java text-center">
                      Assortment
                    </th>
                    <th key="header_item" className="bc-java text-center">
                      Item
                    </th>
                    <th key="header_upc" className="bc-java text-center">
                      UPC
                    </th>
                    <th key="header_length" className="bc-java text-center">
                      L (cm) <span className="required">*</span>
                    </th>
                    <th key="header_width" className="bc-java text-center">
                      W (cm) <span className="required">*</span>
                    </th>
                    <th key="header_height" className="bc-java text-center">
                      H (cm) <span className="required">*</span>
                    </th>
                    <th key="header_cbm" className="bc-java text-center">
                      CBM <span className="required">*</span>
                    </th>
                    <th key="header_cbm" className="bc-java text-center">
                      Total CBM
                    </th>
                    <th
                      key="header_weight_gross"
                      className="bc-java text-center"
                    >
                      Gross <span className="required">*</span>
                    </th>
                    <th key="header_weight_net" className="bc-java text-center">
                      Net <span className="required">*</span>
                    </th>
                    <th
                      key="header_total_weight_gross"
                      className="bc-java text-center"
                    >
                      Gross <span className="required">*</span>
                    </th>
                    <th
                      key="header_total_weight_net"
                      className="bc-java text-center"
                    >
                      Net <span className="required">*</span>
                    </th>
                    <th key="header_style" className="bc-java text-center">
                      Style <span className="required">*</span>
                    </th>
                    {!view && (
                      <>
                        <th
                          key="header_input_row"
                          className="bc-java text-center"
                        >
                          Size Ratio
                        </th>
                        <th
                          key="header_add_row"
                          className="bc-java text-center"
                        >
                          Add
                        </th>
                        <th
                          key="header_delete_row"
                          className="bc-java text-center"
                        >
                          Delete
                        </th>
                      </>
                    )}
                  </tr>
                </thead>

                <tbody className="scrollable-table-container">
                  {poDetailsData && poDetailsData?.length > 0 ? (
                    <>
                      {poDetailsData.map((obj, index) => {
                        return (
                          <tr
                            key={`table_parent_key_${index}`}
                            onMouseEnter={() => setHoverData(obj)}
                            onMouseLeave={() => setHoverData(null)}
                          >
                            {/* SL */}
                            <td className="t-body sticky">{index + 1}</td>
                            {/* From (start_range) */}
                            <td className="t-body sticky">
                              <InputNumber
                                style={{ width: "80px" }}
                                placeholder="From"
                                value={obj?.start_range}
                                onChange={(value) => {
                                  onChangePoDetailsValue(
                                    value,
                                    obj?.unique_id,
                                    "start_range",
                                  );
                                  calculateQty(obj?.unique_id);
                                }}
                                formatter={(value) =>
                                  commaSeparateNumber(value)
                                }
                                precision={0}
                                size="small"
                              />
                            </td>
                            {/* To (end_range) */}
                            <td className="t-body sticky">
                              <InputNumber
                                style={{ width: "80px" }}
                                placeholder="To"
                                value={obj?.end_range}
                                onChange={(value) => {
                                  onChangePoDetailsValue(
                                    value,
                                    obj?.unique_id,
                                    "end_range",
                                  );
                                  calculateQty(obj?.unique_id);
                                }}
                                formatter={(value) =>
                                  commaSeparateNumber(value)
                                }
                                precision={0}
                                size="small"
                              />
                            </td>
                            {/* Qty (carton_qty) */}
                            <td className="t-body sticky">
                              <InputNumber
                                style={{ width: "80px" }}
                                placeholder="Qty"
                                value={obj?.carton_qty}
                                disabled
                                formatter={(value) =>
                                  commaSeparateNumber(value)
                                }
                                precision={2}
                                size="small"
                              />
                            </td>
                            {/* Assortment */}
                            <td className="t-body sticky">
                              <Input
                                style={{ width: "80px" }}
                                placeholder="Assortment"
                                value={obj?.assortment}
                                onChange={(event) => {
                                  onChangePoDetailsValue(
                                    event.target.value,
                                    obj?.unique_id,
                                    "assortment",
                                  );
                                }}
                                size="small"
                              />
                            </td>
                            {/* Item */}
                            <td className="t-body sticky">
                              <Input
                                style={{ width: "80px" }}
                                placeholder="Item"
                                value={obj?.item}
                                onChange={(event) => {
                                  onChangePoDetailsValue(
                                    event.target.value,
                                    obj?.unique_id,
                                    "item",
                                  );
                                }}
                                size="small"
                              />
                            </td>
                            {/* UPC */}
                            <td className="t-body sticky">
                              <Input
                                style={{ width: "80px" }}
                                placeholder="UPC"
                                value={obj?.upc}
                                onChange={(event) => {
                                  onChangePoDetailsValue(
                                    event.target.value,
                                    obj?.unique_id,
                                    "upc",
                                  );
                                }}
                                size="small"
                              />
                            </td>
                            {/* Length */}
                            <td className="t-body sticky">
                              <InputNumber
                                style={{ width: "80px" }}
                                placeholder="Length"
                                value={obj?.length}
                                onChange={(value) => {
                                  onChangePoDetailsValue(
                                    value,
                                    obj?.unique_id,
                                    "length",
                                  );
                                  calculateCBM(obj?.unique_id);
                                }}
                                precision={2}
                                size="small"
                              />
                            </td>
                            {/* Width */}
                            <td className="t-body sticky">
                              <InputNumber
                                style={{ width: "80px" }}
                                placeholder="Width"
                                value={obj?.width}
                                onChange={(value) => {
                                  onChangePoDetailsValue(
                                    value,
                                    obj?.unique_id,
                                    "width",
                                  );
                                  calculateCBM(obj?.unique_id);
                                }}
                                precision={2}
                                size="small"
                              />
                            </td>
                            {/* Height */}
                            <td className="t-body sticky">
                              <InputNumber
                                style={{ width: "80px" }}
                                placeholder="Height"
                                value={obj?.height}
                                onChange={(value) => {
                                  onChangePoDetailsValue(
                                    value,
                                    obj?.unique_id,
                                    "height",
                                  );
                                  calculateCBM(obj?.unique_id);
                                }}
                                precision={2}
                                size="small"
                              />
                            </td>
                            {/* CBM */}
                            <td className="t-body sticky">
                              <InputNumber
                                style={{ width: "80px" }}
                                placeholder="CBM"
                                value={obj?.cbm}
                                disabled
                                size="small"
                              />
                            </td>
                            {/* Total CBM */}
                            <td className="t-body sticky">
                              <InputNumber
                                style={{ width: "80px" }}
                                placeholder="Total CBM"
                                value={obj?.total_cbm}
                                disabled
                                size="small"
                              />
                            </td>
                            {/* Carton Gross */}
                            <td className="t-body sticky">
                              <InputNumber
                                style={{ width: "80px" }}
                                placeholder="Gross"
                                value={obj?.gross}
                                onChange={(value) => {
                                  onChangePoDetailsValue(
                                    value,
                                    obj?.unique_id,
                                    "gross",
                                  );
                                  calculateTotalGrossWeight(obj?.unique_id);
                                }}
                                formatter={(value) =>
                                  commaSeparateNumber(value)
                                }
                                precision={2}
                                size="small"
                              />
                            </td>
                            {/* Carton Net */}
                            <td className="t-body sticky">
                              <InputNumber
                                style={{ width: "80px" }}
                                placeholder="Net"
                                value={obj?.net}
                                onChange={(value) => {
                                  onChangePoDetailsValue(
                                    value,
                                    obj?.unique_id,
                                    "net",
                                  );
                                  calculateTotalNetWeight(obj?.unique_id);
                                }}
                                formatter={(value) =>
                                  commaSeparateNumber(value)
                                }
                                precision={2}
                                size="small"
                              />
                            </td>
                            {/* Total Gross */}
                            <td className="t-body sticky">
                              <InputNumber
                                style={{ width: "80px" }}
                                placeholder="Gross"
                                value={obj?.total_gross}
                                disabled
                                formatter={(value) =>
                                  commaSeparateNumber(value)
                                }
                                precision={2}
                                size="small"
                              />
                            </td>
                            {/* Total Net */}
                            <td className="t-body sticky">
                              <InputNumber
                                style={{ width: "80px" }}
                                placeholder="Net"
                                value={obj?.total_net}
                                disabled
                                formatter={(value) =>
                                  commaSeparateNumber(value)
                                }
                                precision={2}
                                size="small"
                              />
                            </td>
                            {/* Style */}
                            <td className="t-body sticky">
                              <Select
                                onChange={(value) => {
                                  onChangePoDetailsValue(
                                    value,
                                    obj?.unique_id,
                                    "style_id",
                                  );
                                  setStyleNo(styleList?.find(i=>i.style_id == value)?.style_no);
                                }}
                                showSearch
                                style={{
                                  width: "100%",
                                }}
                                placeholder="Search to Select"
                                optionFilterProp="children"
                                defaultActiveFirstOption={false}
                                value={obj?.style_id}
                                size="small"
                              >
                                {styleList &&
                                  styleList?.length > 0 &&
                                  styleList?.map((po, idx) => (
                                    <Option key={idx} value={po?.style_id}>
                                      {po?.style_no?.length > 35
                                        ? `${po?.style_no?.slice(0, 35)}...`
                                        : po?.style_no}
                                    </Option>
                                  ))}
                              </Select>
                            </td>
                            {!view && (
                              <td
                                className="t-body"
                                onClick={() => {
                                  openDrawer();
                                  getPackingColorList(obj?.unique_id);
                                }}
                              >
                                <EditOutlined
                                  className="cursore-pointer"
                                  style={{ fontSize: "16px" }}
                                />
                              </td>
                            )}
                            {!view && (
                              <td
                                className="t-body"
                                onClick={() => {
                                  copyRow(obj?.unique_id);
                                }}
                              >
                                <PlusCircleOutlined
                                  className="cursore-pointer"
                                  style={{ fontSize: "16px", color: "Green" }}
                                />
                              </td>
                            )}
                            {!view && (
                              <td
                                className="t-body"
                                onClick={() => {
                                  removeRow(obj?.unique_id);
                                }}
                              >
                                <MinusCircleOutlined
                                  className="cursore-pointer"
                                  style={{ fontSize: "16px", color: "red" }}
                                />
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </Card>
          <Drawer
            title="Item Input"
            placement="right"
            onClose={closeDrawer}
            open={isDrawerOpen}
            width={1000}
          >
            <SizeTracking
              selectedPoColorList={selectedPoColorList}
              poDetailsData={poDetailsData}
              PackReqForm={PackReqForm}
              selectedPOUniqueID={selectedPOUniqueID}
              isDrawerOpen={isDrawerOpen}
              insertSizeTableData={insertSizeTableData}
              isEdit={isEdit}
              setPoDetailsData={setPoDetailsData}
              // updatePackReqFormValue={updatePackReqFormValue}
              styleNo={styleNo}
            />
          </Drawer>
        </div>
      </Form>
      <div>
        <RowInfoUI rowData={hoverData} className="row-ui-bottom" size="small" />
      </div>
    </>
  );
};
export default PackingDetails;
