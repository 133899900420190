import {
  CopyOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Form, Modal, Row } from "antd";
import { useState } from "react";
import {
  commaSeparateNumber,
  isArrayAndHasValue,
} from "../../../utils/functions";
import DrawerForm from "./DrawerForm";
import "./POTable.css";
import HandleNotification from "common/Notification";

const StylePODetails = (props) => {
  // Props
  const {
    dynamicSizeList,
    purchesList,
    styleList,
    view,
    showOrderTotal,
    showTotalRowWise,
    poDetailsData,
    setPoDetailsData,
    mode,
    useBothStylePo,
    breakdown_type,
  } = props;

  // Antd
  const { confirm } = Modal;

  // States
  const [open, setOpen] = useState(false);
  const [hoverData, setHoverData] = useState(null);
  const [uniqueId, setUniqueId] = useState(0);
  const [aIsEdit, setAIsEdit] = useState(false);

  const [form] = Form.useForm();

  const deleteRow = (unique_id) => {
    const poDetailsDataCopy = structuredClone(poDetailsData);
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === unique_id,
    );
    const updatedSizes = foundPoItem?.sizes?.map((item) => {
      return {
        ...item,
        is_delete: 1,
      };
    });

    foundPoItem["sizes"] = updatedSizes;
    foundPoItem["is_delete"] = 1;

    setPoDetailsData(poDetailsDataCopy);
  };

  const removeRow = (item) => {
    confirm({
      title: "Do you want to delete this row?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteRow(item?.unique_id);
      },
    });
  };

  const onChangePoDetailsValue = (value, index, keyName) => {
    // Copy poDetailsData state array
    const poDetailsDataCopy = [...poDetailsData];
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === index,
    );

    foundPoItem[keyName] = value;

    setPoDetailsData(poDetailsDataCopy);
  };

  const setInternalPo = (value, index, keyName) => {
    let foundInternalPo = purchesList.find(
      (item) => item.original_po === value,
    )[keyName];
    // Update the internal PO of changed PO data
    onChangePoDetailsValue(foundInternalPo, index, keyName);
  };

  const setOriginalPo = (value, index, keyName) => {
    let foundOriginalPo = purchesList.find(
      (item) => item.internal_po === value,
    )[keyName];
    // Update the original PO of changed PO data
    onChangePoDetailsValue(foundOriginalPo, index, keyName);
  };

  const copyRow = (index) => {
    setOpen(true);
    setAIsEdit(true);

    const foundPoItem = poDetailsData[index];
    const clonedFoundPoItem = structuredClone(foundPoItem);

    clonedFoundPoItem?.sizes?.forEach((sizeItem, index) => {
      sizeItem.po_detail_id = 0;
    });

    clonedFoundPoItem["sub_style_id"] = clonedFoundPoItem?.sub_style_info?.id;

    const updatePoDetails = [
      ...poDetailsData,
      {
        ...clonedFoundPoItem,
        unique_id: poDetailsData?.length,
        sub_style_id: clonedFoundPoItem?.sub_style_info?.id,
      },
    ];
    setUniqueId(updatePoDetails?.length - 1);
    form.setFieldsValue(clonedFoundPoItem);
    setPoDetailsData(updatePoDetails);
  };

  const getSizeValue = (breakdown_type, item) => {
    switch (breakdown_type) {
      case "1":
        return item.sales_order_quantity;
      case "0":
        return item.sales_order_price;
      default:
        return 0;
    }
  };

  const editRow = (index) => {
    setOpen(true);
    setAIsEdit(true);
    setUniqueId(index);

    const clone = [...poDetailsData];

    const foundPoItem = clone?.find((item) => item?.unique_id === index);
    const clonedFoundPoItem = structuredClone(foundPoItem);

    const size = {};
    clonedFoundPoItem?.sizes?.forEach((item) => {
      size[`${item.name}_${item.id}`] = getSizeValue(breakdown_type, item);
    });

    const sales_order_price_given = clonedFoundPoItem?.sizes?.some(
      (item) => item?.common_price === 1,
    );

    form.setFieldsValue({
      ...clonedFoundPoItem,
      style_id: clonedFoundPoItem?.sub_style_info?.style_id
        ? clonedFoundPoItem?.sub_style_info?.style_id
        : clonedFoundPoItem?.style_id || clonedFoundPoItem?.style?.id,
      sub_style_id:
        clonedFoundPoItem?.sub_style_info?.id ||
        clonedFoundPoItem?.sub_style_id,
      color_id: !isNaN(clonedFoundPoItem?.color)
        ? clonedFoundPoItem?.color
        : clonedFoundPoItem?.color_id || clonedFoundPoItem?.color?.color_id,
      brand: clonedFoundPoItem?.brand_info?.name,
      sales_order_price_given: sales_order_price_given ? true : false,
      ...size,
    });
  };

  const addNewRow = () => {
    setOpen(true);
    setAIsEdit(false);

    const clonedPoDetailsData = structuredClone(poDetailsData);
    const lastPoItemId =
      clonedPoDetailsData[clonedPoDetailsData.length - 1]?.unique_id;

    const updatePoDetails = [
      ...clonedPoDetailsData,
      {
        unique_id: lastPoItemId || lastPoItemId === 0 ? lastPoItemId + 1 : 0,
      },
    ];

    setPoDetailsData(updatePoDetails);

    setUniqueId(updatePoDetails.length - 1);
  };

  const showSizeColumnTotal = (sizeObject, breakdownType) => {
    let sizeColumnTotal = 0;

    poDetailsData?.forEach((item) => {
      const foundSize = item?.sizes?.find(
        (sizeItem) => sizeItem?.id === sizeObject?.id,
      );
      sizeColumnTotal +=
        breakdownType === "1"
          ? foundSize?.sales_order_quantity
          : foundSize?.sales_order_price;
    });

    return sizeColumnTotal;
  };

  const styleWiseColorList = (value, index) => {
    const foundStyle = styleList.find((item) => item?.style_info?.id === value);
    const subStyle = foundStyle?.style_info?.sub_style;

    onChangePoDetailsValue(subStyle, index, "color_list_obj");

    const get_brand_info = styleList.find(
      (item) => item?.style_info?.id === value,
    ).style_info?.brand_info;

    const poDetailsDataCopy = [...poDetailsData];

    poDetailsDataCopy.find((item) => item?.unique_id === index)["brand_info"] =
      get_brand_info;

    setPoDetailsData([...poDetailsDataCopy]);
  };

  const getSubStyle = (poId, colorId) => {
    const poDetailsDataCopy = [...poDetailsData];
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === poId,
    );
    const foundColor = foundPoItem?.color_list_obj?.find(
      (colorItem) => colorItem?.color_info?.id === colorId,
    );
    const sub_style_info_obj = {
      ...foundColor,
      sub_style_no: foundColor?.sub_style_no,
    };

    onChangePoDetailsValue(sub_style_info_obj, poId, "sub_style_info");
  };

  const updateSizeQuantity = (props) => {
    const { value, unique_id, size_object, breakdownType } = props;

    const poDetailsDataCopy = [...poDetailsData];

    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === unique_id,
    );
    const foundSize = foundPoItem?.sizes?.find(
      (item) => item?.id === size_object?.value,
    );

    if (foundSize) {
      if (breakdownType === "1") {
        foundSize.sales_order_quantity = value;
      }
      if (breakdownType === "0") {
        foundSize.sales_order_price = value;
      }
    } else {
      if (foundPoItem.sizes) {
        foundPoItem?.sizes?.push({
          id: size_object?.value,
          name: size_object?.label,
          ...(breakdownType === "1" && { sales_order_quantity: value }),
          sales_order_price:
            breakdownType === "0" ? value : foundPoItem?.unit_price,
          delivery_date:
            foundPoItem?.delivery_date ||
            poDetailsDataCopy?.[0]?.sizes?.[0]?.delivery_date,
          po_detail_id: foundPoItem?.po_detail_id || 0,
        });
      } else {
        foundPoItem["sizes"] = [
          {
            id: size_object?.value,
            name: size_object?.label,
            ...(breakdownType === "1" && { sales_order_quantity: value }),
            sales_order_price:
              breakdownType === "0" ? value : foundPoItem?.unit_price,
            delivery_date:
              foundPoItem?.delivery_date ||
              poDetailsDataCopy?.[0]?.sizes?.[0]?.delivery_date,
            po_detail_id: foundPoItem?.po_detail_id || 0,
            common_price: foundPoItem?.common_price,
          },
        ];
      }
    }

    setPoDetailsData(poDetailsDataCopy);
  };

  const setDeliveryDate = (value, index, from) => {
    const foundPoItemInternal = purchesList.find(
      (item) => item?.internal_po === value,
    );
    const foundPoItemOriginal = purchesList.find(
      (item) => item?.original_po === value,
    );
    const foundPoItem =
      from === "from_internal_po" ? foundPoItemInternal : foundPoItemOriginal;
    const delivery_date = foundPoItem?.delivery_date || null;

    onChangePoDetailsValue(delivery_date, index, "delivery_date");
  };

  const RowInfoUI = ({ rowData }) => {
    return (
      <div
        style={{ padding: "10px", display: rowData ? "unset" : "none" }}
        className="row-ui-bottom"
      >
        <div>
          <strong>Internal PO:</strong> {rowData?.internal_po || "N/A"},
          <strong>Original PO:</strong> {rowData?.original_po || "N/A"},
          <strong>Style:</strong> {rowData?.style?.style_no || "N/A"},
          <strong>Color:</strong> {rowData?.color?.name || "N/A"},
          <strong>Substyle:</strong>{" "}
          {rowData?.sub_style_info?.sub_style_no || "N/A"}
        </div>
      </div>
    );
  };

  const changeSizesPoMapId = (value, index) => {
    // Change po_map_id inside every sizes in selected item
    const poDetailsDataCopy = structuredClone(poDetailsData);
    const foundPoItem = poDetailsDataCopy?.find(
      (item) => item?.unique_id === index,
    );

    // Insert newly selected po_map_id to the selected item
    foundPoItem["po_map_id"] = value;
    foundPoItem["sizes"] = foundPoItem?.sizes?.map((sizeItem) => {
      return {
        ...sizeItem,
        po_map_id: value,
      };
    });

    setPoDetailsData(poDetailsDataCopy);
  };

  const setPoMapId = (value, index, keyName) => {
    let foundPoId = purchesList.find((item) => item.internal_po === value)[
      keyName
    ];

    // Update the po_map_id and size data of changed PO data
    changeSizesPoMapId(foundPoId, index);
  };

  const setPoMapIdByOriginalPO = (value, index, keyName) => {
    let foundPoId = purchesList.find((item) => item.original_po === value)[
      keyName
    ];

    // Update the po_map_id of changed PO data
    onChangePoDetailsValue(foundPoId, index, "po_map_id");
  };

  const childProps = {
    form,
    view,
    poDetailsData,
    unique_id: uniqueId,
    purchesList,
    setOriginalPo,
    setPoMapId,
    setInternalPo,
    setPoMapIdByOriginalPO,
    onChangePoDetailsValue,
    setDeliveryDate,
    styleList,
    styleWiseColorList,
    color_list_obj: [],
    getSubStyle,
    isArrayAndHasValue,
    dynamicSizeList,
    updateSizeQuantity,
    commaSeparateNumber,
    aIsEdit,
    setUniqueId,
    uniqueId,
    setAIsEdit,
  };

  const renderSizeValue = (size, obj, breakdown_type) => {
    const sizeId = size?.size_id || size?.id;
    const sizesArray = obj?.sizes;
    const foundSize = sizesArray?.find((item) => item.id === sizeId);
    if (breakdown_type === "1") {
      return foundSize?.sales_order_quantity || 0;
    }
    if (breakdown_type === "0") {
      return foundSize?.sales_order_price || 0;
    }
  };

  return (
    <Form form={form}>
      <div>
        <Card
          title={
            <span style={{ fontSize: "14px", marginBottom: "10px" }}>
              Order Details
            </span>
          }
        >
          <div className="custome-table">
            <table border="1" style={{ marginBottom: "30px" }}>
              <thead>
                <tr>
                  <th
                    key="header_serial_number"
                    style={{ backgroundColor: "#C5E0B4" }}
                  >
                    SL.
                  </th>
                  <th
                    key="header_original_po"
                    style={{ backgroundColor: "#C5E0B4" }}
                  >
                    PO
                  </th>
                  <th key="header_style" style={{ backgroundColor: "#C5E0B4" }}>
                    Style
                  </th>
                  <th
                    key="header_sub_style"
                    style={{ backgroundColor: "#C5E0B4" }}
                  >
                    Sub Style
                  </th>
                  <th key="header_color" style={{ backgroundColor: "#C5E0B4" }}>
                    Color
                  </th>

                  {/* Size Row Headers */}
                  {isArrayAndHasValue(dynamicSizeList) &&
                    breakdown_type !== "2" &&
                    dynamicSizeList?.map((obj, index) => {
                      return (
                        <th
                          key={`size_column_key_${index}`}
                          style={{ backgroundColor: "#C5E0B4" }}
                        >
                          {obj.label}
                        </th>
                      );
                    })}

                  {breakdown_type === "1" && (
                    <>
                      <th
                        key="header_total"
                        style={{ backgroundColor: "#C5E0B4" }}
                      >
                        Total Qty
                      </th>
                      <th
                        key="header_unit_price"
                        style={{ backgroundColor: "#548235", color: "white" }}
                      >
                        Unit Price
                      </th>
                    </>
                  )}

                  {breakdown_type !== "2" && (
                    <th
                      key="header_total_value"
                      style={{ backgroundColor: "#548235", color: "white" }}
                    >
                      Total Value
                    </th>
                  )}

                  {/* Copy */}
                  {!view && breakdown_type === "1" && (
                    <>
                      <th
                        key="header_copy_row"
                        style={{ backgroundColor: "#C5E0B4" }}
                      >
                        Copy
                      </th>
                    </>
                  )}

                  {/* Edit */}
                  {!view && breakdown_type !== "2" && (
                    <th
                      key="header_edit_row"
                      style={{ backgroundColor: "#C5E0B4" }}
                    >
                      Edit
                    </th>
                  )}

                  {/* Delete */}
                  {!view &&
                    isArrayAndHasValue(poDetailsData) &&
                    breakdown_type === "1" && (
                      <>
                        <th key="header_delete_row" className="bc-java ">
                          Delete
                        </th>
                      </>
                    )}
                </tr>
              </thead>

              <tbody className="scrollable-table-container">
                {isArrayAndHasValue(poDetailsData) ? (
                  <>
                    {poDetailsData
                      ?.filter((item) => item?.is_delete !== 1)
                      .map((obj, index) => {
                        return (
                          <tr
                            key={`table_parent_key_${index}`}
                            onMouseEnter={() => setHoverData(obj)}
                            onMouseLeave={() => setHoverData(null)}
                            onDoubleClick={() => {
                              if (mode === "view") return;
                              if (breakdown_type === "2") return;
                              editRow(obj?.unique_id);
                            }}
                          >
                            {/* SL */}
                            <td className="t-body sticky">{index + 1}</td>
                            {/* Original PO */}
                            <td className="t-body sticky">
                              {obj?.original_po || ""}
                            </td>
                            {/* Style */}
                            <td className="t-body sticky">
                              {styleList.find(
                                (item) =>
                                  item?.style_info?.id ===
                                  (obj?.style_id || obj?.style?.id),
                              )?.style_info?.style_no || ""}
                            </td>
                            {/* Substyle */}
                            <td className="t-body sticky">
                              {obj?.sub_style_info?.sub_style_no || ""}
                            </td>
                            {/* Color */}
                            <td className="t-body sticky">
                              {obj?.color?.color_info?.name ||
                                obj?.color?.name ||
                                ""}
                            </td>

                            {/* Dynamic Sizes Data */}
                            {isArrayAndHasValue(dynamicSizeList) &&
                              breakdown_type !== "2" &&
                              dynamicSizeList?.map((size, index) => {
                                return (
                                  <td
                                    className="t-body"
                                    key={`dynamic_size_key_${index}`}
                                  >
                                    {renderSizeValue(size, obj, breakdown_type)}
                                  </td>
                                );
                              })}

                            {/* For Unit Price Breakdown */}
                            {breakdown_type === "1" ? (
                              <>
                                {/* Total Qty */}
                                <td
                                  className="t-body"
                                  style={{ backgroundColor: "#E7E6E6" }}
                                >
                                  {commaSeparateNumber(
                                    showTotalRowWise(obj, breakdown_type),
                                  )}
                                </td>
                                {/* Unit Price */}
                                <td className="t-body">
                                  {obj?.unit_price || 0}
                                </td>
                                {/* Total Value */}
                                <td
                                  className="t-body"
                                  style={{ backgroundColor: "#E7E6E6" }}
                                >
                                  {obj?.unit_price
                                    ? Number(
                                        obj?.unit_price *
                                          showTotalRowWise(obj, breakdown_type),
                                      ).toFixed(2)
                                    : 0}
                                </td>
                              </>
                            ) : (
                              ""
                            )}

                            {/* For Size Wise Price Breakdown */}
                            {breakdown_type === "0" ? (
                              <>
                                {/* Total */}
                                <td
                                  className="t-body"
                                  style={{ backgroundColor: "#E7E6E6" }}
                                >
                                  {commaSeparateNumber(
                                    showTotalRowWise(obj, breakdown_type),
                                  )}
                                </td>
                              </>
                            ) : (
                              ""
                            )}

                            {/* Actions */}
                            {/* Copy */}
                            {!view && breakdown_type === "1" && (
                              <td
                                className="t-body"
                                onClick={() => {
                                  copyRow(index);
                                }}
                              >
                                <CopyOutlined
                                  className="cursore-pointer"
                                  style={{ fontSize: "16px", color: "Green" }}
                                />
                              </td>
                            )}
                            {/* Edit */}
                            {!view && breakdown_type !== "2" && (
                              <td
                                className="t-body"
                                onClick={() => {
                                  editRow(index);
                                }}
                              >
                                <EditOutlined
                                  className="cursore-pointer"
                                  style={{ fontSize: "16px", color: "Green" }}
                                />
                              </td>
                            )}
                            {/* Delete */}
                            {!view &&
                              isArrayAndHasValue(poDetailsData) &&
                              breakdown_type === "1" && (
                                <td
                                  className="t-body"
                                  onClick={() => {
                                    removeRow(obj);
                                  }}
                                >
                                  <MinusCircleOutlined
                                    className="cursore-pointer"
                                    style={{ fontSize: "16px", color: "red" }}
                                  />
                                </td>
                              )}
                          </tr>
                        );
                      })}
                  </>
                ) : (
                  ""
                )}
                {isArrayAndHasValue(poDetailsData) &&
                breakdown_type !== "2" &&
                isArrayAndHasValue(dynamicSizeList) ? (
                  <tr>
                    <td
                      className="t-body"
                      colSpan="5"
                      style={{ textAlign: "right", fontWeight: "bold" }}
                    >
                      Total
                    </td>
                    {dynamicSizeList?.map((sizeObject, index) => {
                      return (
                        <td
                          className="t-header"
                          key={`total_count_key_${index}`}
                        >
                          {commaSeparateNumber(
                            showSizeColumnTotal(sizeObject, breakdown_type),
                          )}
                        </td>
                      );
                    })}
                    {breakdown_type !== "2" && (
                      <td
                        className="t-body"
                        style={{ textAlign: "left", fontWeight: "bold" }}
                        colSpan={view ? "3" : "6"}
                      >
                        {" "}
                        {commaSeparateNumber(
                          showOrderTotal(poDetailsData, breakdown_type),
                        )}
                      </td>
                    )}
                  </tr>
                ) : (
                  ""
                )}
              </tbody>
            </table>
          </div>
        </Card>
      </div>
      <Row>
        <Col span={4}>
          <Button
            style={{
              width: "200px",
              position: "fixed",
              paddingRight: "30px",
              bottom: "1vh",
            }}
            type="primary"
            className="mt-2"
            icon={<PlusOutlined />}
            size="small"
            onClick={addNewRow}
            disabled={
              view ||
              open ||
              !dynamicSizeList?.length > 0 ||
              breakdown_type === "0" ||
              breakdown_type === "2"
            }
            hidden={view || open}
          >
            Add
          </Button>
        </Col>
        <Col span={4}>
          <div>
            <RowInfoUI
              rowData={hoverData}
              className="row-ui-bottom"
              size="small"
            />
          </div>
        </Col>
      </Row>

      {open && (
        <DrawerForm
          props={childProps}
          open={open}
          setOpen={setOpen}
          uniqueId={uniqueId}
          totalOrder={commaSeparateNumber(showOrderTotal(poDetailsData))}
          useBothStylePo={useBothStylePo}
          aIsEdit={aIsEdit}
          setPoDetailsData={setPoDetailsData}
          breakdownType={breakdown_type}
          addNewRow={addNewRow}
        />
      )}
    </Form>
  );
};

export default StylePODetails;
