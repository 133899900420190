import "./index.css";
import { List, Table, Typography } from "antd";
import Card from "antd/lib/card/Card";
import { PNL_REPORT_LINE_WISE_TILL_TODAY_EXPENSE } from "apiServices/API_ENDPOINTS";
import { getData } from "apiServices/common";
import React, { useEffect, useState } from "react";
import { formatNumberWithCommas } from "utils/functions";

export default function DailySewingPerformance() {
  const [data, setData] = useState({
    listOfBasicInfo: [],
    dataSource: [],
    isLoading: false,
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Day Cost Take",
      dataIndex: "line",
      key: "line",
      children: data?.dataSource[0]?.line.map((item, i) => ({
        title: item.title,
        dataIndex: ["line", i, "value"],
        key: `line_${i + 1}`,
        align: "right",
        render: (text) => formatNumberWithCommas(text),
      })),
    },
    {
      title: "Total Cost Taka",
      dataIndex: "total_cost_taka",
      key: "total_cost_taka",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Sewing Prod (pc)",
      dataIndex: "sewing_prod_pc",
      key: "sewing_prod_pc",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Average CM (USD)",
      dataIndex: "average_cm_usd",
      key: "average_cm_usd",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Total CM /Day (USD)",
      dataIndex: "total_cm_day_usd",
      key: "total_cm_day_usd",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Total CM /Day (Taka)",
      dataIndex: "total_cm_day_taka",
      key: "total_cm_day_taka",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: "Operating Profit/(Loss) Taka",
      dataIndex: "operating_profit_loss_taka",
      key: "operating_profit_loss_taka",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    {
      title: `Cumulative Net Profit in ${data?.listOfBasicInfo[0]?.value} (Taka)`,
      dataIndex: "cumulative_net_profit_taka",
      key: "cumulative_net_profit_taka",
      align: "right",
      render: (text) => formatNumberWithCommas(text),
    },
    // {
    //   title: "Cumulative Net Profit in September' 2024 (Taka)",
    //   dataIndex: "cumulative_net_profit_sep_2024_taka",
    //   key: "cumulative_net_profit_sep_2024_taka",
    // },
  ];

  const getListOfBasicInfo = (item) => {
    const basicInfo = [
      {
        name: "Name of Month",
        value: item?.current_month,
      },
      {
        name: "Total Days During the Month",
        value: item?.total_days,
      },
      {
        name: "Total Days of Leave During the Month",
        value: item?.total_days_of_weekend_holiday,
      },
      {
        name: "Total Working Days During the Month",
        value: item?.total_working_days_of_current_month,
      },
    ];
    return basicInfo;
  };

  const getInputDataConvertToDataSourceFormate = (input) => {
    let cumulative_net_profit_taka = 0;
    const dataSource = input.table_data.map((item, index) => {
      cumulative_net_profit_taka += item.operating_profit_loss_take;

      return {
        key: index + 1,
        date: item.date,
        is_working_day: item.is_working_day,
        total_cost_taka: item.total_cost,
        sewing_prod_pc: item.total_sewing_production_pc,
        average_cm_usd: item.avg_cm_usd,
        total_cm_day_usd: item.total_cm_day_usd,
        total_cm_day_taka: item.total_cm_day_taka,
        operating_profit_loss_taka: item.operating_profit_loss_take,
        cumulative_net_profit_taka:
          index === 0
            ? item.operating_profit_loss_take
            : cumulative_net_profit_taka,
        line: item.lines.map((line, i) => ({
          title: line.line_name,
          value: line?.cost || 0,
        })),
      };
    });
    return dataSource;
  };

  const getDailySewingPerformanceData = async () => {
    setData({
      ...data,
      isLoading: true,
    });
    try {
      const query = `${PNL_REPORT_LINE_WISE_TILL_TODAY_EXPENSE}`;
      const response = await getData(query);
      if (response?.data?.code === 200) {
        getListOfBasicInfo(response?.data?.data);
        setData({
          listOfBasicInfo: getListOfBasicInfo(response?.data?.data),
          dataSource: getInputDataConvertToDataSourceFormate(
            response?.data?.data,
          ),
          isLoading: false,
        });
      }
    } catch (error) {
      console.log("Error getting Daily Sewing Performance data");
    }
  };

  useEffect(() => {
    getDailySewingPerformanceData();
  }, []);

  const totalOfLine = (data, index) => {
    return data
      .reduce((acc, current) => {
        return acc + parseFloat(current.line[index].value);
      }, 0)
      ?.toFixed(2);
  };

  return (
    <Card
      title={
        <>
          <Typography.Title
            level={3}
            style={{ textAlign: "center", marginBottom: 0 }}
          >
            CONCORDE GARMENTS LTD.
          </Typography.Title>
          <Typography.Title
            level={4}
            style={{ textAlign: "center", marginTop: 0 }}
          >
            DAILY SEWING PERFORMANCE REPORT
          </Typography.Title>
        </>
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <List
          style={{
            width: "35%",
          }}
          bordered
          dataSource={data.listOfBasicInfo}
          renderItem={(item) => (
            <List.Item style={{ margin: "2px 4px" }}>
              <Typography.Text strong>{item?.name}:</Typography.Text>
              <Typography.Text>{item?.value}</Typography.Text>
            </List.Item>
          )}
        />
      </div>
      <div style={{ marginTop: 20, width: "100%" }}>
        <Table
          bordered
          className="daily-sewing-performance-table"
          columns={columns}
          dataSource={data.dataSource}
          scroll={{ x: true }}
          pagination={false}
          loading={data.isLoading}
          rowClassName={(record) => (record.is_working_day ? "green-row" : "")}
          summary={(dataSource) => (
            <>
              {console.log(dataSource)}
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={1}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 0))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={2}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 1))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={3}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 2))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={4}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 3))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={5}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 4))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={6}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 5))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={7}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 6))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={8}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 7))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={9}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 8))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={10}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 9))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={11}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 10))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={12}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 11))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={13}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 12))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={14}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 13))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={15}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 14))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={16}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 15))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={17}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 16))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={18}>
                  {formatNumberWithCommas(totalOfLine(dataSource, 17))}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={19}>
                  {formatNumberWithCommas(
                    dataSource
                      .reduce((acc, current) => {
                        return acc + parseFloat(current.total_cost_taka);
                      }, 0)
                      ?.toFixed(2),
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={20}>
                  {formatNumberWithCommas(
                    dataSource
                      .reduce((acc, current) => {
                        return acc + parseFloat(current.sewing_prod_pc);
                      }, 0)
                      ?.toFixed(2),
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={21}>
                  {formatNumberWithCommas(
                    dataSource
                      .reduce((acc, current) => {
                        return acc + parseFloat(current.average_cm_usd);
                      }, 0)
                      ?.toFixed(2),
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={22}>
                  {formatNumberWithCommas(
                    dataSource
                      .reduce((acc, current) => {
                        return acc + parseFloat(current.total_cm_day_usd);
                      }, 0)
                      ?.toFixed(2),
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={23}>
                  {formatNumberWithCommas(
                    dataSource
                      .reduce((acc, current) => {
                        return acc + parseFloat(current.total_cm_day_taka);
                      }, 0)
                      ?.toFixed(2),
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={24}>
                  {formatNumberWithCommas(
                    dataSource
                      .reduce((acc, current) => {
                        return (
                          acc + parseFloat(current.operating_profit_loss_taka)
                        );
                      }, 0)
                      ?.toFixed(2),
                  )}
                </Table.Summary.Cell>
                <Table.Summary.Cell align="right" index={24}>
                  {formatNumberWithCommas(
                    dataSource
                      .reduce((acc, current) => {
                        return (
                          acc + parseFloat(current.cumulative_net_profit_taka)
                        );
                      }, 0)
                      ?.toFixed(2),
                  )}
                </Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          )}
        />
      </div>
    </Card>
  );
}
